#resultCntr {
  padding-top: 20px;
  width: 100%;
  background: $black_lighte;

  @include breakpoint(sm){
    .px_0 { padding-left: 0px; padding-right: 0px;}
    
  }
  

  // resulttab Box start
  .resulttabBox {
    width: 100%;
    overflow: hidden;

    ul {
      list-style: none;
      display: flex;
      float: left;
      overflow: hidden;
      margin-bottom: 0px;
      border-radius: 5px 5px 0 0;
      background: $Sky_dark_blue;
      padding-left: 0px;

      li {
        a {
          display: block;
          padding: 7px 10px 5px 10px;
          @include Link_mediumFont;
          color: $white;

          &.active {
            background: $yellow;
          }
        }
      }
    }
  }
  // resulttab Box ends

  //result search
  .Resultsearch {
    width: 100%;
    margin-bottom: 20px;
    background: $white;
    transition: all ease-in-out 0.5s;
    z-index: 8;

    .searchHideBtn {
      padding: 5px 10px 0 10px;
      display: inline-block;
      position: relative;
      right: 2px;
      top: 2px;
      font-size: 16px;
      text-decoration: none;
      color: $Sky_darken_blue;
      border-radius: 5px;
      border: 1px solid $Sky_dark_blue;
    }
    .tabBox {
      justify-content: start;
      li {
        &:first-child {
          border-top-left-radius: 0px;
        }
        &:last-child {
          border-top-right-radius: 0px;
        }
      }
    }
    .searchBlock {
      border-radius: 0px;
      background: none;
    }

    .flightSearch {
      .label .inputField {
        border: 1px solid #ccc;
        border-radius: 0.25rem;
      }
      .feildrow {
        padding: 10px 0 0 0;
        background: inherit;
      }
    }

    .moreoptionBtn,
    .resetBtn {
      margin-left: 0px;
      background: $Sky_blue;
    }
    .searchButton {
      position: relative;
      left: 0px;
      transform: translate(0);
      margin-top: 20px;
      border-radius: 50px;
      padding: 12px 12px 8px 12px;
    }
  }
  //

  //

  // resultkey Box start
  .resultkeyBox {
    padding: 6px 0 4px 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: $yellow;

    .resultkey {
      display: flex;
      padding-left: 20px;
      .title {
        @include p_bigFont;
        color: $white;
        position: relative;
        padding-right: 20px;
        padding-top: 2px;
        font-weight: 600;

        @include breakpoint(md) {
          display: none;
        }

        &::after {
          width: 20px;
          height: 20px;
          content: "";
          position: absolute;
          right: -9px;
          top: -19%;
          transform: translateY(50%);
          background: url(#{$imagePath}icons/arrow-bar-right.svg) left top
            no-repeat;
          background-size: contain;
        }
      }
      ul {
        list-style: none;
        padding-left: 0px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0px;
        @include breakpoint(sm) {
          display: block;
        }
        li {
          padding: 5px 20px 0 20px;
          @include p_mediumFont;
          color: $white;

          @include breakpoint(md) {
            padding: 5px 20px 0 0;
           
          }

          @include breakpoint(sm) {
            @include p_smallFont;
            float: left;
          }
        }
      }
    }
    .changeSearch {
      ul {
        display: flex;
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;

        @include breakpoint(sm) {
          display: contents;
        }

        li {
          padding: 5px 15px 0 15px;
          position: relative;

          @include breakpoint(sm) {
           float: right;
           padding: 0px 15px 0 15px;
          }

          &:after {
            width: 1px;
            height: 15px;
            content: "";
            position: absolute;
            right: 0px;
            top: 8px;
            background: $white;

            @include breakpoint(sm) {
              display: none;
            }
          }
          &:last-child:after {
            display: none;
          }
          a {
            @include Link_mediumFont;
            color: $white;
            white-space: nowrap;

            @include breakpoint(sm) {
              @include Link_smallFont;
            }
          }
        }
      }
    }
  }
  // resultkey Box ends

  // resultheader Box start
  .resultheaderBox {
    padding: 5px 20px 0 20px;
    background: $black;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding: 0px;
    }

    .left {
      display: flex;
      align-items: center;
      padding: 10px 0 0 0;

      @include breakpoint(md) {
        padding-top: 0px;
      }

      @include breakpoint(sm) {
        padding: 5px 20px 0 20px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        background: $yellow;
      }

      .title {
        @include p_bigFont;
        color: $white;
        @include breakpoint(sm) {
          color: $black;
        }
      }
      p {
        @include p_smallFont;
        color: $white;
        padding-left: 20px;
        margin-bottom: 0px;
        @include breakpoint(sm) {
          color: $black;
        }
      }

      .filterburger {
        margin-right: 20px;
        display: none;
        border: none;
        background: none;
        @include breakpoint(md) {
          display: block;
        }
        @include breakpoint(sm) {
          position: absolute;
          left: 20px;
          top: 5px;
        }
        i {
          font-size: 24px;
          color: $white;
          line-height: 20px;
          transition: all 0.5s ease;

          @include breakpoint(sm) {
            color: $black;
          }
        }

        &.closeIcon {
          .bi-x{ display: block;}
          .bi-filter-left{ display: none;}
        }
        &.burgerIcon {
          .bi-x{ display: none;}
          .bi-filter-left{ display: block;}
        }
      }
    }
    .right {
      width: auto;

      @include breakpoint(sm) {
        width: 100%;
        padding: 5px 20px 0 20px;
      }
      ul {
        list-style: none;
        padding-left: 0px;
        margin-bottom: 0px;
        position: relative;
        @include breakpoint(sm) {
          display: flex;
          justify-content: space-between;

        }

        li {
          padding: 0 10px;
          float: left;
          position: relative;
          @include breakpoint(sm) {
            float: none;
            padding: 0px;
          }

          a, .bestresults {
            padding: 10px 0 5px 0;
            display: block;
            @include Link_mediumFont;
            color: $white;
            border: none;
            background: transparent;

            @include breakpoint(sm) {
              @include Link_smallFont;
            }
          }
          
          .BestResultDropdown {
            width: 190px;
            background-color: $black;
            color: $white;
            padding: 0px;
            box-shadow: none;
            border: none;
            margin-top: 10px;
            @include p_mediumFont;
            position: absolute;
            right: 0px;
            top: 30px;
            z-index: 2;
            background: #fff;
            box-shadow: 0 0 5px #00000094;
            .dropvalue {
              padding: 5px 10px 0 10px;
              display: block;
              color: $black;
              font-size: 16px;
  
              &.active {
                color: $white;
                background-color: $yellow;
              }
              &:focus,
              &:hover {
                color: $white;
                background: $yellow;
              }
            }
            

          }
          
        }
      }
    }
  }
  // resultheader Box ends

  // filterresult container start
  #filterresultCntr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    // filter box start
    .filterBox {
      width: 350px;
      padding: 20px 5px;
      background: $white;
      @include breakpoint(md) {
        display: none;

        &.showfilterBox {
          z-index: 3;
          display: block;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 100%;
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        }
      }
      .css-1elwnq4-MuiPaper-root-MuiAccordion-root { margin-bottom: 5px; box-shadow: none;}
      .css-1elwnq4-MuiPaper-root-MuiAccordion-root::before {display: none;}
      .css-o4b71y-MuiAccordionSummary-content { margin: 8px 0; font-size: 16px; font-weight: 600;}
      .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {min-height: 31px;}

      .rangelider {
        margin-top: 40px;
        width: 100%;
        .css-nnid7-MuiSlider-valueLabel { background-color: $yellow; border-radius: 17px;}
        .css-187mznn-MuiSlider-root { color:  $yellow !important}
        
      }

      .resetblock {
        ul {
          list-style: none;
          padding-left: 0px;
          display: flex;
          justify-content: center;

          li {
            padding: 0 5px;

            a {
              padding: 8px 12px 5px 12px;
              display: block;
              @include Link_smallFont;
              color: $white;
              border-radius: 50px;
              background: $Sky_dark_blue;
              font-weight: 600;
            }
          }
        }
      }

      .no_filter {
        width: 100%;
        padding: 10px 15px;
        background: $white;
        @include p_smallFont;
        color: $black_light1;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid $black_lighte;
        p {
          margin-bottom: 0px;
        }
      }

      .tabheader {
        color: $white;
        background: $Sky_dark_blue;
        border-left: 5px solid $Sky_blue;
      }
      .css-i4bv87-MuiSvgIcon-root {
        color: $white;
      }
      .select_icon {
        .css-i4bv87-MuiSvgIcon-root {
          color: $Sky_dark_blue;
        }
      }

      .filterBlock {
        width: 100%;
        margin-bottom: 10px;

        .row_filter {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          overflow: hidden;
           border-bottom: 1px solid $black_light9;
          &:last-child{ border-bottom: none;}

          .left {
            width: auto;
          }
          .right {
            width: auto;
            white-space: nowrap;

          }
        }

        .css-ahj2mt-MuiTypography-root {
          font-size: 12px !important;
        }
        .duration_slider {
          margin-bottom: 10px;
          width: 100%;
        }
        .travelTime {
          width: 70px;
        }
        .countinput {
          width: 70px;
          margin-left: 10px;
        }
        .travelTimeSlider {
          padding-left: 20px;
          padding-right: 20px;
        }

        // .accordion {
        //   background-color: $Sky_blue;
        //   color: $white;
        //   cursor: pointer;
        //   padding: 10px 18px;
        //   width: 100%;
        //   border: none;
        //   text-align: left;
        //   outline: none;
        //   font-size: 15px;
        //   transition: 0.4s;
        //   border-radius: 5px 5px 0 0;
        // }

        // .active, .accordion:hover {
        //   background-color: $Sky_dark_blue;
        // }

        .panel {
          margin-top: 10px;
          padding: 0 18px;
          background-color: white;
          max-height: 0;
          border-left: 1px solid $Sky_dark_blue;
          border-right: 1px solid $Sky_dark_blue;
          overflow: hidden;
          transition: max-height 0.2s ease-out;
        }
      }
    }
    //filter box ends

    // result box start
    .resultBox {
      width: calc(100% - 350px);
      padding-left: 20px;
      position: relative;

      @include breakpoint(md) {
        width: 100%;
        padding-left: 0px;
      }

      .resultblock {
        margin-bottom: 20px;
        padding: 15px;
        width: 100%;
        border: 1px solid $white_gray1;
        border-radius: 5px;
        background: $white;
        color: $black_light1;
        &:first-child{ margin-top: 20px;}
        position: relative;
        z-index: 0;

        @include breakpoint(sm){
          overflow: hidden
        }

        .head {
          @include p_bigFont;
          margin-bottom: 20px;
          font-weight: 600;

          span {
            @include p_smallFont;
            font-weight: 300;
          }
        }

        .airlinelogo {
          width: 45px;
          img {
            width: 100%;
          }
        }
        .airlineInfo {
          padding-left: 10px;
          .airlineName {
            @include p_smallFont;
            font-weight: 600;
          }
          .airlineNumber {
            @include p_smallFont;
            i{ margin-left: 5px; display: inline-block;}
          }
        }

        .leg_detail {
          width: 100%;

          @include breakpoint(sm){ margin-top: 30px;}

          .city {
            width: 70px;
            float: left;
            .time {
              font-weight: 700;
            }
          }

          .connecting_block {
            padding: 13px 10px 0 24px;
            width: calc(100% - 140px);
            position: relative;
            float: left;
            margin-left: -7px;
            margin-right: 7px;

            .leg_points {
              position: relative;
              text-align: center;

              &::before {
                content: "";
                width: 30px;
                height: 22px;
                display: block;
                position: absolute;
                left: -20px;
                top: -12px;
                background: #fff url(#{$imagePath}icons/tack_off.svg) left
                  center no-repeat;
                background-size: contain;
              }

              &::after {
                content: "";
                position: absolute;
                right: -5px;
                top: -10px;
                height: 22px;
                background: #fff url(#{$imagePath}icons/landing_airline.svg)
                  right center no-repeat;
                width: 30px;
                background-size: contain;
              }

              .stopage {
                width: calc(100% - 60px);
                position: relative;
                margin-left: 20px;
                display: flex;
                justify-content: center;

                &::after {
                  width: 100%;
                  height: 1px;
                  text-align: center;
                  content: "";
                  position: absolute;
                  left: 0px;
                  top: 0;
                  background: $black_light9;
                }
              }
              .stops {
                width: 100px;
                display: inline-block;
                margin-top: -10px;
                line-height: 2em;
                position: relative;
                z-index: 1;
                &::before {
                  content: "";
                  width: 15px;
                  height: 15px;
                  display: inline-block;
                  position: unset;
                  margin-top: 2px;
                  border: 2px solid gray;
                  border-radius: 100%;
                  background: #fff;
                }
              }
              .layovertime {
                width: 100%;
                position: absolute;
                top: -40px;
                left: 0px;
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 20px;
        }

        .available_flight {
          padding-top: 20px;
          width: 100%;

          @include breakpoint(md){
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            padding-bottom: 20px;
          }

          .available_flight_row {
            padding: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include p_smallFont;
            background: $white_gray1;
            border-top: 1px solid $black_light9;
              &:first-child {
                border-top: none;
              }
           
            .itnery_flight_row {
              width: 100%;
              @include p_smallFont;
              background: $white_gray1;
            }
            .itnery_flight {
              margin-top: 10px;
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              
            }

            .flight_img_code_number {
              a{
                img { width: 40px;}
              }
              .code {
                margin: 0 10px;
              }
              label {
                margin-right: 0px;
              }
            }
            .flight_date_airport_code {
              span {
                margin: 0 10px;
              }
            }
            .airport_time_duration_booking_class {
              span {
                margin: 0 10px;
              }
            }
            .flight_airbus {
              span {
                margin: 0 10px;
              }
            }
            .flight_amenities {
              ul {
                list-style: none;
                display: flex;
                margin-bottom: 0px;

                li {
                  padding: 0 5px;

                  .css-i4bv87-MuiSvgIcon-root {
                    font-size: 18px;
                  }
                }
              }
            }
          }

          .buttongroup {
            padding: 10px 0 0 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .booknowBtn {
              padding: 10px 20px 5px 20px;
              display: inline-block;
              @include p_bigFont;
              cursor: pointer;
              font-weight: 700;
              color: $white;
              background: $yellow;
              border: none;
              border-radius: 50px;
            }
            .rightBtn {
              margin: 0 10px;
              display: inline-block;
              @include p_mediumFont;
              cursor: pointer;
              color: $black;
              border: none;
              background: none;

              &:last-child {
                margin-right: 0px;
              }
            }
          }
        }
      }

      .price_list {
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        height: 100%;
        background: $Sky_darken_blue;

        @include breakpoint(sm){
          flex-direction: column-reverse;

          .w_100 { width: 100%; justify-content: space-between;}
        }

        .price_block {
          width: 200px;

          @include breakpoint(sm){
            width: auto;
          }
        }

        .price {
          font-size: 25px;
          color: $white;
          font-weight: bold;
          position: relative;
          min-height: 35px;
          display: block;
          cursor: pointer;
          .price sup {
            font-size: 0.75rem;
          }
        }
      }
      .fare__breakup {
        position: relative;
        float: left;

        .fare__detail {
          color: $white;
          text-decoration: none;
          text-align: center;
          font-weight: 600;

          span {
            padding-left: 8px;
            display: inline-block;
            color: $white;
            font-size: 12px;
            font-weight: 300;
          }
        }
      }
      .select__btn {
        background: $yellow;
        padding: 8px 35px 0 35px;
        color: $white;
        font-size: 16px;
        border-radius: 100px;
        height: 48px;
        text-decoration: none;
        line-height: 37px;
        white-space: nowrap;
        display: block;
      }

      .baggageBlock {
        display: flex;
        justify-content: center;

        @include breakpoint(sm){
          width: 100%;
          justify-content: flex-start;
          margin-bottom: 10px;
        }
        ul {
          width: 130px;
          padding-left: 0px;
          margin-bottom: 0px;
          list-style: none;
          display: flex;
          justify-content: space-between;
          li {
            padding: 10px;
            width: 36px;
            height: 36px;
            background: $white;
            position: relative;

            .icon {
              position: absolute;
              right: 3px;
              top: 3px;
              color: $green;
              width: 15px;
              height: 15px;
            }
            .green { color: $green;}
            .red { color: $red;}

            img {
              width: 100%;
            }
          }
        }
      }

      .loadingPopup {
        padding-top: 50px;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        background: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        z-index: 5px;
        h4 {
          margin-top: 20px;
        }
      }
    }
    //result box ends
  }
  
  // filterresult container ends
}
//result container ends

//price_breakdown_overlay start
.price_breakdown_overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;

  .price_breakdown_overlay_inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .price_breakdown {
      position: relative;
      padding: 20px;
      width: 450px;
      @include borderRadius;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      background: $white;

      .closeBtn {
        float: right;
        margin-bottom: 10px;

        .css-i4bv87-MuiSvgIcon-root {
          color: $yellow;
        }
      }

      h4 {
        padding: 10px 20px 5px 20px;
        margin-bottom: 0px;
        color: $white;
        background: $Sky_dark_blue;
      }

      thead th {
        padding: 0px;
      }
    }
  }
}
//price_breakdown_overlay ends

//Create_offer_Popup start
.Create_offer_Popup {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9;
  overflow-y: hidden;

  .Create_offer_Popup_inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .Create_offer {
      position: relative;
      padding: 20px;
      width: 650px;
      @include borderRadius;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
      background: $white;
      .w_100 {
        width: 100%;
      }
      .closeBtn {
        float: right;
        margin-bottom: 10px;

        .css-i4bv87-MuiSvgIcon-root {
          color: $yellow;
        }
      }

      h4 {
        padding: 10px 20px 5px 20px;
        margin-bottom: 0px;
        color: $white;
        background: $Sky_dark_blue;
      }
      p {
        margin-bottom: 0px;
      }
      .languageselect {
        margin-left: 20px;
        width: 150px;
        .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 5px 10px;
        }
      }
      .offertable {
        @include p_smallFont;
        .css-ahj2mt-MuiTypography-root {
          @include p_smallFont;
        }
        .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
          padding: 0px;
        }
        .css-vqmohf-MuiButtonBase-root-MuiRadio-root:hover {
          background-color: inherit;
        }
        //.css-j204z7-MuiFormControlLabel-root { display: block;}
        .css-dmmspl-MuiFormGroup-root {
          padding-bottom: 5px;
          display: block;
        }
      }
      .css-146nnbl-JoyTextarea-root {
        @include p_smallFont;
      }
      .text {
        @include p_smallFont;
      }
      .offerBtn {
        padding: 10px 20px 5px 20px;
        display: inline-block;
        @include p_bigFont;
        cursor: pointer;
        font-weight: 700;
        color: $white;
        text-decoration: none;
        background: $yellow;
        border: none;
        border-radius: 50px;
      }
      .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: $Sky_dark_blue;
      }
      .css-14lo706 {
        display: block;
        span {
          opacity: 0;
        }
      }
    }
  }
}
//Create_offer_Popup ends
