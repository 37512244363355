.sortingBox { overflow: hidden; margin: 20px 0 14px 0;
@include breakpoint(sm){ margin: 10px 0; }
	ul { list-style: none;	 display:flex; padding: 0px; margin:0px;
	@include breakpoint(sm){white-space: nowrap; padding-bottom: 13px;
    overflow-x: auto;
    overflow-y: hidden;}
	li{ margin-right:10px; cursor:pointer; font-family: 'Montserrat', sans-serif; font-size: 13px; font-weight:500; float:left; width: 175px; background: $white;   border: 1px solid #CED0D4;    border-radius: 40px;   padding: 4px 10px 4px 43px;    position: relative; 
	@include breakpoint(sm){ font-size: 11px; width: 120px; padding: 4px 15px; }
	.icons {    position: absolute; left: 24px;  top: 5px;
	@include breakpoint(sm){ display: none; }
		}
	.price { font-size: 14px; color:#06c300; font-weight: 600; display:block; 
		@include breakpoint(sm){ font-size: 12px; }
	}
	&:hover, .selected { background:linear-gradient(93deg,#53b2fe,#065af3); color:$white;  border-color:#0260da;
		.price { color:$white; }
	}
	}
	.all_results {padding: 15px;  text-align: center;
	@include breakpoint(sm){ padding: 12px; }
		&.selected { background:linear-gradient(93deg,#53b2fe,#065af3); color:$white; border-color:#0260da;
		@include breakpoint(sm){ padding: 12px 33px; }
		}
	}
	
	}
}


/*Apply Filter */

.applyedFilterBox { padding-left: 20px; padding-bottom: 10px; width: 100%; overflow: hidden; background: $black;
	
	h5{ color: $white;}
	p{ color: $white;}
	ul {padding: 0px; margin:0px auto; list-style: none; float:left; margin-right:-5px; margin-bottom: 0px;
			li{ position: relative;  margin: 10px 5px 0px 0px; border: 0;  padding: 6px 28px 2px 10px;  border-radius: 4px; background: $white; color: $black;
		font-size: 12px; float: left; }
		.resetFilter {font-size: 21px; position: absolute; top: 2px;  right: 6px; cursor:pointer; }
		}
	.clear_filter {margin-top: 15px; margin-left:5px;  display: inline-block; font-size: 13px; color: $white; text-decoration: underline; cursor:p }

	&.bookingfilter { padding-top: 10px; padding-left: 10px; background: $white; border: 1px solid $black_lighte; border-radius: 5px;
		h6{ margin-bottom: 0px;}
		ul{
			li{ background: $white_gray1; color: $black;}
		}
	}
}

.FilterContainer { padding: 0 20px; margin: 10px 0;}



