/*Slider with text*/
.sliderwithinfo { margin-bottom: 20px; width: 100%; position: relative; overflow: hidden; padding:30px 0 10px 0;
    .container { position: relative;}
    .page_title { display: flex; font-size: 40px; color:#000000; font-weight: 300; padding-bottom: 30px; text-align: left; position: relative; 
        span { padding-right: 10px; font-size: 30px; font-weight: 600;}
        
        @include breakpoint(lg){ text-align:left; padding-bottom:26px; margin: 0px; }
        @include breakpoint(md){ font-size: 35px; 
            span {font-size: 25px;}
        }
        @include breakpoint(sm){ font-size: 30px; 
            span {font-size: 20px;}
        }
        @include breakpoint(xs){ font-size: 20px; 
            span {font-size: 15px;}
        }
    }

    //.swiper-horizontal {padding: 0px 40px 0 60px;}
    .swiper-button-prev, .swiper-button-next { width: 50px; height: 50px; background: $white; border-radius: 50px;}
    .swiper-button-prev::after, .swiper-button-next::after {font-size: 23px;
        font-weight: 800;}
    .swiper-button-prev, .swiper-rtl .swiper-button-next {left: 1px;}
    .swiper-button-next, .swiper-rtl .swiper-button-prev {right: 19px;}

    @include breakpoint(sm){padding-top: 14px;} 
        .holder {
            @include breakpoint(lg){overflow-x: auto; overflow-y: hidden;  padding-bottom: 17px; margin-right:-20px;}
        }
        .block { width: 100%;}
        .items { padding: 0 20px 0 0; position:relative;
        @include breakpoint(sm){ padding:0 15px 0 0; } 
            figure { margin: 0px; position: relative;
                &:before { content:''; width:100%; height:96px; position: absolute; left: 0px; bottom: 0px; background: transparent linear-gradient(180deg, #00000000 0%, #000000 90%) 0% 0% no-repeat padding-box;
                @include breakpoint(sm){ height: 60px; }
                }
                img {width: 100%;  border-radius: 10px;}
            }
            
            figcaption { font-size: 14px; font-weight: 400;color:#fff; position: absolute; left: 10px; bottom: 10px;  text-transform: capitalize; width: 100%;  
            @include breakpoint(md){ font-size: 12px; }
            @include breakpoint(sm){ bottom:5px; width: 90%; }
            strong { font-size: 20px;
                @include breakpoint(xl){ font-size: 16px; }
                @include breakpoint(md){ font-size: 14px; }
                /*@include breakpoint(sm){ display:block; }*/
            }
        }
        .detail { padding:10px; border: 1px solid #CBCBCB; border-radius: 0 0 10px 10px; font-size: 14px; color:#000000;
            justify-content: space-between;
            align-items: center;
        @include breakpoint(xs){ font-size: 10px; padding:6px; }
        .redBtn { padding: 10px 20px 5px 20px; font-size: 14px; text-decoration: none; border: none; white-space: nowrap;}
                p { margin-bottom: 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;	text-transform: capitalize; 
                @include breakpoint(sm){ margin:0px; }
                }
                .price { margin-bottom: 10px; display: flex; justify-content: space-between; align-items: center; font-family:$yellow; font-size: 20px; font-weight: 700; color: #000000; 
                @include breakpoint(xl){ font-size: 18px; }
                @include breakpoint(sm){ font-size: 16px; }
                @include breakpoint(xs){ font-size: 14px; }
                    .discount { display: block; color:#7C7C7C; text-decoration: line-through; font-weight: 400; padding-right:5px; }
                    .perPerson {color:#7C7C7C; font-size: 14px; font-weight: 400; }
                }
                .QueryBtn { text-decoration:none; padding: 5px 10px; white-space:nowrap; border-radius: 5px; font-size: 18px; color: #fff; background: transparent linear-gradient(89deg, #F15747 0%, #F68E63 100%) 0% 0% no-repeat padding-box;
                @include breakpoint(sm){font-size: 14px; }
                }
        }
        }
        
        .slick-list.draggable { padding: 0 100px 0 0!important; }
        .viewall {  font-size:14px; text-decoration:none; padding:5px 10px 0 10px; width: 101px;
        text-align: center; font-weight: 500;  position: absolute;  right: 0px;  top: -5px; 
            @include breakpoint(md){ top: 2px; }
            @include breakpoint(sm){ border:none; right:0px; }
            @include breakpoint(xs){ top:-9px;  }
        }
        /**/
        &.int-slider {
            
            figcaption { font-size: 46px; line-height: 55px; color:#fff; position: absolute; left: 0px; bottom: 20px;  text-align: center; width: 100%;  
            @include breakpoint(xl){font-size: 38px;  }
            @include breakpoint(sm){font-size: 29px; line-height: 30px;  bottom: 10px; }
            span { font-size: 14px; letter-spacing:2.5px; line-height:21px; display:block; text-transform:uppercase;
            @include breakpoint(sm){font-size: 12px; letter-spacing:1px; }
            @include breakpoint(xs){font-size: 10px; }
            }
            
        }
        figure{	&:before { content:''; border-radius: 0px 0px 10px 10px; } }
        }
        /**/
    }