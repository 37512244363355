#footerCntr {
  padding: 50px 0 0 0;
  width: 100%;
  overflow: hidden;
  color: $white;
  background: $Sky_dark_blue;
  .list {
    ul {
      list-style: none;
      padding-left: 0px;
      li {
        padding: 5px 0;
        color: $white;
        span {
          margin-right: 10px;
          font-size: 17px;
          color: $yellow;
          font-weight: 600;
        }
        a {
          font-size: 16px;
          color: $white;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .footer_img {
    width: 100%;
    @include borderRadius;
    border: 10px solid $white;
  }
  .social {
    ul {
      list-style: none;
      display: flex;
      padding-left: 0px;
      li {
        padding: 0 20px 0 0;
        a {
          font-size: 16px;
          color: $white;
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }

  h3 {
    color: $white;
  }
  h2 {
    color: $white;
    font-weight: 600;
    line-height: 40px;
    span {
      color: $yellow;
      font-size: 30px;
    }
  }

  .copyright {
    padding: 30px 0 10px 0;
    color: $white;
    font-size: 13px;
    background: $Sky_darken_blue;
  }
}
