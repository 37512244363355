#myBookingCntr{
    width: 100%;
    padding: 20px 0;
    background: $black_lighte;

    .handleBtn { width: 100%; padding: 9px 30px 0 30px; display: inline-block; text-align: center; text-decoration: none; line-height: 30px; font-weight: 600; text-transform: uppercase; font-size: 18px; border-radius: 5px; color: #fff; background: #5db2e8; cursor: pointer; border: none;}
    .handleBtn i{ margin-right: 10px;}
    .handleBtn:hover { background: #f6871f; transition: all .5s; }

    .resetBtn { margin-left: 10px; font-size: 16px; color: $yellow; font-weight: 600; border: none; background: $white;}

    .bookingHead {width: 100%; padding: 10px 20px; background: $Sky_dark_blue; display: flex;align-items: center; justify-content: space-between;
        h3{ margin-bottom: 0px; color: $white;
            span{ font-size: 16px;}
        }
        .notification {@include Link_mediumFont; line-height: 19px; color: $white;
            span { display: inline-block; width: 20px; height: 20px; line-height: 25px; border-radius: 100%; background-color: $white; font-size: 14px; font-weight: 600; text-align: center; color: $yellow;}
        }
        .bookingheadright { margin-bottom: 0px; list-style: none; display: flex; justify-content: center;
            li{ padding-left: 10px; padding-right: 10px; position: relative;
                &:after { width: 1px; height: 22px; content: ""; background: rgba(252, 251, 251, 0.5); position: absolute; left: 0px; top: 0px;}
                &:first-child::after{ display: none}
                a{@include Link_mediumFont; color: $white;}
            }
        }
    }

    .mybookingBox {width: 100%; background: $white; overflow: hidden; @include borderRadius; border: 1px solid $white;
        
        .bookingBlock { width: 100%; display: flex; flex-wrap: wrap;
            .bookingLeft { padding: 20px; width: 350px;
                .listBlock { margin-bottom: 20px; width: 100%; border-left: 5px solid $Sky_blue; background: $white_gray1;
                    h4{ padding: 10px 0 0 10px;}
                    ul{ list-style: circle;
                        li{ padding: 0px 0 5px 0; 
                            a{@include Link_mediumFont; color: $black_light1;}
                        }
                    }
                }
            }
            .SearchBooking { width: calc(100% - 350px); padding: 20px; display: flex; align-items: start; background: $black_lighte;
                .holder { width: 100%;

                .SearchBookingtab { margin-bottom: 0px; width: 100%; display: flex;justify-content: center; list-style: none;
                   li{
                    padding: 5px 0 5px 15px; margin: 0 5px; @include p_mediumFont; color: $black_light1; border: 1px solid $white;
                   }
                   .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked { color: $yellow;}
                   .css-1hbvpl3-MuiSvgIcon-root {width: 20px; height: 20px;}
                   .css-11zohuh-MuiSvgIcon-root {width: 20px; height: 20px;}
                }
                .Bookingtabdetail { width: 70%; padding: 20px; width: 100%; background: $white;
                    .form-control:focus { box-shadow: none;}
                    .form-select { box-shadow: none;}
                   
                }
             }
            }
        }
    }

    .bookingresultBox {
        width: 100%; background: $white; @include borderRadius; border: 1px solid $white;
        
        .bookingFilter { padding: 20px 20px 0 20px; width: 100%; display: flex; justify-content: space-between;
            .filtrleft { width: auto; display: flex;
                .filterBlock { margin-right: 10px; background: $white; position: relative;
                    
                    .filterButton{ padding: 5px 10px; border-radius: 5px; font-size: 16px; font-weight: 600; position: relative; background: $white_gray1; border: none;} 
                    .filterdropdown { padding: 10px 10px 0 10px; width: 200px; position: absolute; left: 0px; top: 35px; z-index: 2; background: $white; box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
                        
                        &.morefilter { width: 400px; padding-bottom: 20px;
                            .labelTitle {margin-bottom: 5px;} 
                            .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {width: 100%;}
                             .css-4jnixx-MuiStack-root > .MuiTextField-root { min-width: 100%;}
                             .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input { height: 30px; padding: 5px 14px;}
                             .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root { top: -5px; font-size: 13px;}
                            // .css-4jnixx-MuiStack-root { padding-top: 0px;}
                            .noteText { margin-top: 3px; font-size: 11px;}
                            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input { padding: 7px 14px;}

                            .carrierBlock { width: 40px; height: 40px; margin-right: 10px;
                                .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {  width: 100%; height: 100%;}
                                input {padding: 5px; text-align: center; width: 100%; height: 100%;}
                            }
                            .datepicker { width: 100%;}
                            .datepickIcon { position: relative;
                                .calenderIcon { width: 17px; height: 17px; position: absolute; right: 15px; top: 3px;}
                            }
                        }

                        h5 { font-size: 16px; color: $Sky_blue;}
                        ul{list-style: none; padding-left: 0px; margin-bottom: 0px;
                            
                            li{ padding: 5px 0; padding-left: 30px; border-bottom: 1px solid $black_lighte; position: relative;
                                a{ @include Link_smallFont; color: $black_light1;
                                }
                                .icon {position: absolute; left: 0px; font-size: 20px;}

                                &.orange{
                                    svg{ color: $yellow;}
                                }
                                &.green{ 
                                    svg{color: $green;}
                                }
                                &.red{
                                    svg{ color: $red;}
                                }
                                &:last-child{ border-bottom: 0px;}
                            }

                            &.p_L li {padding-left: 10px;
                                .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {font-size: 14px;}
                                .css-1hbvpl3-MuiSvgIcon-root {width: 20px; height: 20px;}
                                .css-11zohuh-MuiSvgIcon-root {width: 20px; height: 20px;}
                                .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate { color: $yellow;}
                                .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root { padding: 0px; margin-right: 10px;}
                                .m_r { margin-right: 10px;}
                                .Btnstyle { padding: 2px 10px;} 
                            }

                        }
                        
                    }
                }
            }
            .sorting { width: auto; position: relative;
                .shortingBtn { border: none; background: $white; color: $black_light1; @include p_mediumFont; font-weight: 600;}
                .shortdropdown { padding: 20px; width: 200px; position: absolute; right: 0px; top: 30px; background: $white; box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
                    ul{ list-style: none; padding-left: 0px;
                        li{ padding: 5px 0; border-bottom: 1px solid $black_lighte;
                            a{@include Link_smallFont; color: $black_light1;}
                        }
                    }
                }    
            }
        }

        .bookinglisttable { margin: 0 20px 20px 20px; border-top: 5px solid $Sky_dark_blue; border-bottom: 5px solid $Sky_dark_blue; border-radius: 10px; overflow: hidden;
            .bookingList { width: 100%; 
                table, td { border-collapse: collapse; border: 1px solid $black_light9;}
                td{ padding: 20px 5px 10px 5px; text-align: center; font-size: 16px; color: $black_light1;}
                .pnr { font-size: 20px; font-weight: 600; line-height: 20px;}
                .supnr { font-weight: 600;}
                .bookingway {display: flex; justify-content: center;
                    .departure {display: flex; align-items: center; justify-content: center; flex-direction: column;}
                    .airportName {font-size: 20px; color: $Sky_dark_blue; font-weight: 600;}
                    .airlineName { width: 100px;
                        img { width: 50%;}
                    }
                    .return {display: flex; align-items: center; justify-content: center; flex-direction: column;}
                    .bookingdate { font-weight: 600;}
                }
                .BookingStatus {
                    &.green{ color: $green;}
                    &.red{ color: $red;}
                    &.yellow{ color: $yellow;}
                }

                .Totalfare { font-size: 25px; font-weight: 600  ;}
                .agentName {
                    span{ display: block;}
                }
            }
        }

        .bookingPagination { padding: 10px; width: 100%; display: flex; justify-content: space-between; align-items: center; font-size: 14px; font-weight: 600; border: 1px solid $black_light9; border-top: none; border-bottom: none;
            .numberOfResult {
                .title { margin-right: 15px; display: inline-block;}
            }
            .pageCount { display: flex;
                p{ margin-bottom: 0px; margin-right: 15px; line-height: 35px;}
                .counter { padding: 7px 0px 5px 7px; background: transparent; border-radius: 5px; border:  1px solid $black_light9;}
            }
            .pagination { display: flex;
                button { margin-left: 5px; padding: 5px 15px; font-size: 16px; border: none; color: $white; font-weight: 600; border-radius: 5px; background: $Sky_blue;}
            }
        }
    }
}