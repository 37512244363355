// pnrBox start here
.pnrBox { margin-top: 10px; width: 100%; background: $white; padding: 10px;
    .pnrHead { padding: 5px; width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; background: $Sky_blue;
        ul{list-style: none; display: flex; margin-bottom: 0px; padding-left: 0px;
            li{ padding: 10px 10px 5px 10px; margin-right: 5px; font-size: 16px; color: $Sky_dark_blue; font-weight: 600; background: $white;
                span{font-weight: 300;}
            }
        }
        .Notifications { margin-right: 10px; @include p_mediumFont; color: $white; text-decoration: none;
            span { margin-left: 10px; display: inline-block; width: 20px; height: 20px; border-radius: 100%; text-align: center; line-height: 25px; font-size: 14px; color: $white; background: $yellow;}
        }
    }

    .pnrdetailBlock { padding-top: 20px; width: 100%; display: flex; justify-content: space-between;

        .pnrDetail {
            ul{ list-style: none; margin-bottom: 0px; padding-left: 20px;
                li{ @include p_smallFont;
                    .Filekeytitle {width: 150px; display: inline-block; color: $Sky_dark_blue; font-weight: 600;}
                    .edite_date { font-size: 14px;}
                }
            }
        }
        .pnrHistory {
            .pnrHistoryBtn { padding: 10px 20px 5px 20px; @include p_mediumFont; color: $white; border: none; background: $Sky_darken_blue; }
            .TotlaFare { margin-top: 10px; font-size: 25px; color: $yellow; font-weight: 600;}
        }
    }
}

.booking_title { width: 100%; padding: 10px 0 5px 20px; font-size: 20px; color: $Sky_dark_blue; background: $white_gray1;
    .titletooltipIcon { margin-left: 5px; font-size: 16px; color: $yellow;}
}
    

.book_pass_detail { margin-top: 10px; width: 100%; background: $white; padding: 10px;
    .pass_tab{ width: 100%; display: flex; justify-content: space-between; margin-top: 20px;  width: 100%; float: left;
        ul{list-style: none; margin-bottom: 0px;
            li {float: left; margin-right: 30px;
                a{font-size: 16px; color: $black_light1; font-weight: 600; text-decoration: none; padding: 0 0px 7px 0; display: block; position: relative; cursor: pointer;
                    &::before {content: ""; height: 2px; position: absolute;  bottom: -1px; transition: all 0.5s; width: 0px;
                        display: block;background: #135681;}
                    &:hover { color: #f6871f;
                        &::before {width: 100%;}
                    }
                    &.active { color: #f6871f;
                        &::before {width: 100%;}
                    }
                }
            }
        }
        .passenger_names_ticket { @include p_smallFont; 
            span { margin-left: 20px;}
        }

    }

    p{
        a{ text-decoration: none; color: $Sky_dark_blue;}
    }
}

.booking_form { margin-top: 10px; width: 100%; background: $white; padding: 10px;
    &.tab { border: 1px solid $black_lighte; @include borderRadius;}

    h5{ font-size: 20px; font-weight: 600; color: $Sky_dark_blue; 
        .errorIcon { margin-left: 5px; color: $yellow; font-size: 18px;}
    }

    select {
        &:focus{ box-shadow: none;}
    }
    input {
        &:focus{ box-shadow: none;}
    }
    label { font-size: 14px;font-weight: 600;}
    .btn { padding: 10px 10px 5px 10px; font-size: 14px; color: $white; font-weight: 600; background: $yellow; border: none;
        &.green {background: $green;}
        &.yellow {background: $yellow;}
        &.red {background: $red;}
    }
    
    .RedressNum { padding: 10px 10px 5px 10px; margin-bottom: 10px; font-size: 14px; color: $white; font-weight: 600; background: $yellow; border: none; 
        .downarrow { margin-left: 5px;}
    }
    .RedressNumHide { display: none;}
    .RedressNumShow { display: block;}
    .form-check-input:checked { background-color: $yellow; border-color: $yellow; margin-top: .10em;}

    .toltip { margin-left: 10px; font-size: 18px; color: $Sky_blue;}

}

.Booking_payment_detail { margin-top: 10px; width: 100%; background: $white; padding: 10px;
    ul{ margin-top: 20px; list-style: none; margin-bottom: 0px; padding-left: 0px; display: flex; flex-wrap: wrap;
        li{ margin: 0 15px 5px 15px; @include p_smallFont; width: calc(50% - 30px);
            .paxtitle { padding: 10px 0 5px 10px; margin-right: 10px; width: 150px; display: inline-block; color: $Sky_dark_blue; font-weight: 600; background: $white_gray1;}
           
        }
    }

    .totalFare { margin-top: 10px; padding: 10px 10px 5px 10px; width: 100%; display: flex; justify-content: space-between; align-items: center; background: $Sky_dark_blue;
        .FareTitle {width: 150px; display: inline-block; font-size: 16px; color: $white; font-weight: 600;}
        .Fare { font-size: 25px; color: $white; font-weight: 600;}
    }

    .actionBtn { margin-top: 20px; padding: 10px 20px 5px 20px; font-size: 20px; color: $white; border: none;
        &.green {background: $green;}
        &.yellow {background: $yellow;}
        &.red {background: $red;}
    }
}

.BookingItinerary { overflow: hidden;
    .ItineraryInfo { margin-top: 20px; display: flex; align-items: center;
        .f_logo_code {
            img{ margin-right: 10px; width: 50px;}
            span{ display: inline-block; font-size: 25px; font-weight: 600;}
        }
        .ItineraryIcon { margin: 0 30px; display: flex; list-style: none; padding-left: 0px; margin-bottom: 0px;
            li{ margin: 0 10px;
                a{ font-size: 20px; color: $black_light1; font-weight: 600; text-decoration: none; }
            }
        }

        .Info { @include p_mediumFont; 
            span{ margin-left: 20px; display: inline-block; font-size: 14px;}
        }
    }

    .Itinerarylist { padding: 10px 20px; margin-top: 20px; display: flex; align-items: center; border: 1px solid $black_lightc;
        .f_logo_code {
            img{ width: 30px;}
            span{ margin-left: 5px; display: inline-block; font-size: 16px;}
        }
        .time_date_class { margin: 0 70px; display: flex; 
            ul{ display: flex; list-style: none; margin-bottom: 0px; padding-left: 0px;
                li{ margin: 0 20px; font-size: 16px;}
            }
        }

        .airlineStatus {
            span { display: inline-block; font-size: 16px; }
            .mr_30px { margin-right: 30px;}
        }
    }
}

.dateblock2 { width: 100%; position: relative;
    .react-datepicker-wrapper { width: 100%;}
        .icons { position: absolute; right: 5px; top: 5px;}
        input { width: 100%; padding: .375rem .75rem;background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;border-radius: .25rem;}
    }