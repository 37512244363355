.autosuggest {
    z-index: 9999;
    width: 350px;
    position: absolute;
    background: $white;
    padding: 10px 15px;
    border-radius: 0 0 10px 10px;
    max-height: 200px;
    overflow-x: hidden;

    h6{ padding: 6px 10px 10px 40px; position: relative; border-bottom: 1px dashed $black_lightc;
        svg { color: $yellow;}  
    }
    .flighticon {position: absolute; left: 0px;}
}