@import "core/core.scss";
@import "main/header";
@import "main/banner";
@import "main/airnews";
@import "main/form";
@import "main/result";
@import "main/search/engine";
@import "main/search/autosuggest";
@import "main/payment/ancellary";
@import "main/payment/baggage_pay";
@import "main/payment/flight-itenary";
@import "main/payment/payment";
@import "main/confirmation/confirmation";
@import "main/payment/price-detail";
@import "main/footer";
@import "main/tooltip";
@import "main/stepper";
@import "main/popup/popup";
@import "main/list/sorting";
@import "main/list/package";
@import "main/list/generalServices";
@import "main/profile/profile";
@import "main/profile/breadcrumb";
@import "main/mybooking/Booking";


* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    outline: none;
}

input,
button {
    font-family: 'Anek Telugu', sans-serif;
}
focus {
    outline: none;
}


body {
    font-family: 'Anek Telugu', sans-serif;
    font-size: 14px;
    background-color: $white;
    line-height: normal;
    overflow-x: hidden;
}
.clear{ clear: both;}

.container {width: 1400px; margin: 0 auto;
    @include breakpoint(lg){ width: 100%; max-width: 100%; padding: 0 20px;}
}
#wraperCntr { width: 100%; overflow: hidden;}
#contentCntr { width: 100%; overflow: hidden;}

h2 { font-size: 45px; color: $black_light1; line-height: 50px; font-weight: 800; 
    @include breakpoint(md){ font-size: 35px; }
    @include breakpoint(sm){ font-size: 25px; }
}
h3 { font-size: 30px; color: $black_light1; line-height: 35px; font-weight: 500; 
    @include breakpoint(md){ font-size: 25px; }
    @include breakpoint(sm){ font-size: 20px; }
}
h4 { font-size: 20px; color: $black_light1; line-height: 25px; font-weight: 600; 
    @include breakpoint(md){ font-size: 22px; }
    @include breakpoint(sm){ font-size: 20px; }
}

.redBtn { display: block; padding: 15px 40px 10px 40px; font-size: 16px; color: $white; font-weight: 600; text-transform: uppercase; text-align: center; text-decoration: none; border-radius: 5px; background: $yellow; 
    &:hover { background: $Sky_blue; color: $white; transition: all .5s;}
}