
.stepwizard-step p {
    margin-top: 10px;
    text-align: center!important;
    color: #000!important;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 100%;
    margin: 50px 0;
    position: relative;
}
.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
    top: 28px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-index: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.btn-circle {
    width: 50px;
    height: 50px;
    text-align: center;
    padding: 6px 0;
    font-size: 20px;
    font-weight: bold;
    line-height: 42px;
    border: 2px transparent #fff;
    background: #fff;
}
.btn-circle.btn-primary { background: #5db2e8; border-color: #5db2e8;}
.btn-circle.btn-primary:hover { background: #f6871f; border-color: #f6871f;}

.btn-check:focus+.btn-primary, .btn-primary:focus { box-shadow: none;}

.submitBtn { margin-top: 20px; padding: 9px 30px 0 30px; display: inline-block; text-align: center; text-decoration: none; line-height: 30px; font-weight: 600; text-transform: uppercase; font-size: 18px; border-radius: 5px; color: #fff; background: #5db2e8; cursor: pointer; border: none;}
.submitBtn i{ margin-right: 10px;}
.submitBtn:hover { background: #f6871f; transition: all .5s; }

.EditBtn { margin-top: 20px; padding: 9px 20px 0 20px; white-space: nowrap; display: block; text-align: center; text-decoration: none; line-height: 25px; font-weight: 300; text-transform: capitalize; font-size: 16px; border-radius: 5px; color: #fff; background: #5db2e8; cursor: pointer; border: none;}
.EditBtn i{ margin-right: 10px;}
.EditBtn:hover { background: #f6871f; transition: all .5s; }

.radiobtngroup {width: 100%;}
.radiobtngroup label { color: #000!important;}
.radiobtngroup .btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus { box-shadow: none;}
/* .radiobtngroup .btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {background-color: #f47f12;
    border-color: #f47f12;} 
    .radiobtngroup .btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus { color: #fff!important;}*/
.radiobtngroup .btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {background-color: #f47f12;
    border-color: #f47f12; color: #fff!important;} 