#headerCntr {
  width: 100%;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  background: $white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  z-index: 9;
  .holder {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menuBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    .navbar {
      width: 100%;
      padding: 0px;

      .navbar-toggler {
        //border-color: inherit;
        border: 0px;
        font-size: 30px;
        color: $Sky_darken_blue;
        padding: 0px;
        position: relative;
        top: 5px;

        &:focus { box-shadow: none;}
      }
    }
    
    .navbar-nav {
      padding-left: 0px;
      margin-bottom: 0px;
      .nav-link {
          display: block;
          padding: 7px 10px 0px 10px;
          font-size: 18px;
          color: $black_light1;
          line-height: 50px;
          text-decoration: none;
          position: relative;

          @include breakpoint(md){
            line-height: 35px;
          }

          &:after {
            display: block;
            width: 0px;
            height: 5px;
            content: "";
            transition: all 0.5s;
            position: absolute;
            left: 50%;
            bottom: 0px;
            background: $yellow;
            @include breakpoint (md){
              height: 1px;
            }
          }
          &:hover::after {
            width: 100%;
            left: 0px;
          }
          &:hover {
            color: $yellow;
          }

          &.active {
            color: $yellow;
          }
          &.active::after {
            width: 100%;
            left: 0px;
          }

          
          
        .dropdown-toggle::after {
          border-top: 0px !important;
          border-right: 0px !important;
          border-left: 0px !important;
          border-bottom: 0px !important;
        }

        .logout {
          display: flex;
          padding-left: 30px;
          a {
            margin-left: 15px;
            display: flex;
            align-items: center;
            line-height: 17px;
            font-size: 15px;
            color: $white;
            background: $yellow;
            border-radius: 5px;

            &:hover::after {
              display: none;
            }
            i {
              margin-right: 10px;
            }
            &:hover {
              background: $Sky_blue;
            }
          }
        }
      }

    }

    .dropdown {
      .nav-link{
        &:hover:after {width: inherit;}
        &::after {
            display: inline-block;
            width: inherit;
            height: inherit;
            content: "";
            transition: none;
            position: inherit;
            left: inherit;
            bottom: inherit;
            background: none;
        }
      }
    }

    .dropdown-menu {
      background: $white;
      border-radius: 0px;
      top: 96%;
      a {
        color: $black_light1;
        line-height: 33px;
        font-size: 14px;
        transition: all 0.5s;
        border-bottom: 1px solid $black_lightc;

        &:hover {
          background: $yellow;
          color: $white;
        }
        &::after {
          display: none;
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  .logo {
    width: 90px;
    padding: 0px;
    img {
      width: 100%;
    }
  }

  .loginBtn {
    position: relative;
    display: flex;

    @include breakpoint(md) {
      position: absolute;
      right: 40px;
      top: 15px;
    }
    a {
      font-size: 16px;
      color: $yellow;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      text-decoration: none;
      font-weight: 600;
      white-space: nowrap;
      i {
        margin-right: 5px;
        font-size: 20px;
      }
    }

    &:hover a {
      color: $Sky_darken_blue;
    }
    &:hover .loginlist {
      display: block;
      animation: pulse 0.8s ease;
    }
  }
  .userDropdown {
    padding-bottom: 10px;
    width: auto;
    list-style: none;
    background: $white;
    border-radius: 0 0 10px 10px;
    position: absolute;
    right: 0px;
    top: 56px;
    padding-left: 0px;
    box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.3);

    @include breakpoint(md){ top: 40px; }

    li{ border-bottom: 1px solid $black_lightc;
      &:last-child{ border-bottom: 0px;}
      a{
        padding: 10px 30px 6px 30px!important;
        font-size: 14px!important;
        line-height: inherit!important;
        font-weight: 400;
        color: $black;

        &:hover { background: $yellow; color: $white;}
      }
    }

  }
}

.headerblank {
  width: 100%;
  height: 88px;
}

.headertop {
  padding: 10px 0;
  width: 100%;
  background: $Sky_dark_blue;
  .topsocial {
    width: auto;
    ul {
      margin-bottom: 0px;
      padding-left: 0px;
      list-style: none;
      display: flex;
      li {
        padding: 0 10px;
        line-height: 5px;
        &:last-child {
          padding-left: 0px;
        }
        a {
          font-size: 12px;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }

  .topsupport {
    width: auto;
    @include breakpoint(sm) {
      display: none;
    }
    ul {
      margin-bottom: 0px;
      padding-left: 0px;
      list-style: none;
      display: flex;
      li {
        padding: 0 10px;
        line-height: 5px;
        &:last-child {
          padding-right: 0px;
        }
        a {
          font-size: 12px;
          color: $white;
          text-decoration: none;
          i {
            margin-right: 7px;
            color: $white;
          }
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    width: 0px;
    background-color: #344474;
  }
  100% {
    width: 250px;
    background-color: #202945;
  }
}

@keyframes scroll {
  0% {
    height: 0%;
    overflow: hidden;
    transition: all ease-in-out;
  }
  100% {
    height: 100%;
    overflow: hidden;
    transition: all ease-in-out;
  }
}
