.baggageTooltipBox { padding: 5px 10px; width: 100%; background: $white;
    .baggage_row { padding: 10px 0; width: 100%; display: flex; align-items: center; font-size: 13px; color: $black; border-bottom: 1px dotted $black_light9;
        &:last-child { padding: 0px; border-bottom: none;}

        .popup_icon { width: 20px; display: block;
            .icons {width: 100%;}
        }
        .baggageTitle{ text-transform: uppercase; margin-top: 5px; margin-left: 10px;}
        .blueBg { padding: 10px 10px 5px 10px; color: $white; font-weight: 600; background: $Sky_dark_blue;}
        .toTal { display: flex; justify-content: space-between; align-items: center; width: 100%;}
        .baggagePrice { color: $yellow; font-weight: 600;}
    }
}

.TotalDuration { padding: 10px 10px 0px 10px; width: 100%; text-align: left; background: $white;
    .title { font-size: 18px; 
        span {color: $yellow; font-weight: 600;}
    }
    ul{ list-style: none; padding-left: 0px;
        li{
            padding-bottom: 10px; padding-left: 10px; position: relative; font-size: 11px;

            &::before { width: 5px; height: 5px; border-radius: 100%; content: ''; background: $yellow; position: absolute; left: 0px; top: 4px; z-index: 1;}
            &::after { width: 1px; height: 100%; content: ''; background: $yellow; background: $yellow; position: absolute; left: 2px; top: 4px;}
            &:last-child {padding-bottom: 0px;}
            &:last-child::after {display: none;}
        }
    }
}