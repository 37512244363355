#ResetPassPopup{ width: 100%; height: 100vh; position: relative; display: flex; justify-content: center; align-items: center; background: $white_gray1;
    &::before { opacity: 0.1; z-index: 0px; width: 100%; height: 100%; content: ""; position: absolute; left: 0px; top: 0px; background: url(../../../resources/images/pass_reset_bg.png) center center no-repeat; background-size: cover;}
    
    #formCntr{ background: none;}
    .ResetBlock { width: 600px; padding: 20px; @include boxshadow; z-index: 1;
        .logo { width: 100px; margin: 0 auto;
            img { width: 100%;}
        }
        .submitBtn {max-width: 263px;}
        h4{text-align: center;}
    }
} 