.commonserviceBox {
    width: 100%;
    padding: 20px 0px 30px 0; position: relative;
    border-bottom: 1px solid $black_light1;
    @include breakpoint(sm) {
        border-bottom: 10px solid #F2F2F2;
        float: left;
    }
    h3 {
        font-size: 21px;
        color: #202020;
        margin-bottom: 5px;
        font-weight: 600;
        @include breakpoint(md) {
            font-size: 17px;
            margin-bottom: 2px;
        }
        @include breakpoint(sm) {
            font-size: 20px;
            margin-bottom: 3px;
        }
    }
    p {
        font-size: 15px;
        color: #858585;
        margin-bottom: 0px;
        @include breakpoint(md) {
            font-size: 13px;
        }
        @include breakpoint(sm) {
            font-size: 15px;
        }
    }
    .icon {
        float: left;
        @include breakpoint(md) {
            width: 35px;
        }
        @include breakpoint(sm) {
            width: auto;
        }
    }
    .detail {
        padding-left: 14px;
        float: left;
        @include breakpoint(md) {
            width: calc(100% - 35px);
        }
        @include breakpoint(sm) {
            width: calc(100% - 62px);
        }
    }
    .service_block {
        border-left: 1px solid #ccc;
        width: 33.3%;
        float: left;
        padding-left: 49px;
        @include breakpoint(md) {
            padding-left: 0px;
            border: none;
        }
        &:first-child {
            padding-left: 0px;
            border-left: 0px;
        }
    }
	.slick-dots {
		@include breakpoint(sm){ bottom: 6px; }
	}
}