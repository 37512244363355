@mixin breakpoint($screen) {
    @if $screen == xl {
        @media (max-width: 1900px) {
            @content;
        }
    }

    @if $screen == lg {
        @media (max-width: 1200px) {
            @content;
        }
    }

    @if $screen == md {
        @media (max-width: 1023px) {
            @content;
        }
    }

    @if $screen == sm {
        @media (max-width: 767px) {
            @content;
        }
    }

    @if $screen == xs {
        @media (max-width: 479px) {
            @content;
        }
    }
    
}