@import 'flight-itenary';
@import 'price-detail';
@import 'ancellary';
@import 'baggage_pay';
.payment__wrapper {
    @include boxshadow;
    padding: 20px;
    margin-bottom: 20px;
    overflow: hidden;
    @include breakpoint(md) {
       margin-top: 20px;
    }
    @include breakpoint(sm) {
        margin: 20px -15px 0 -15px;
        padding: 20px 20px 0 20px;
    }
    .payment__stepBox {
        width: 100%;
        margin-bottom: 20px;
        position: relative;

        &::after { width: 100%; height: 1px; content: ""; z-index: 1; background: $black_lightc; position: absolute; left: 0px; top: 50%; transform: translateY(-50%);}

        @include breakpoint(sm) {
            //background: #ECF0FF;
            width: auto;
            margin: 15px 15px 18px 15px;
            //padding: 10px 10px 0 10px;
        }
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px;
            padding-left: 0px;
            li {
                font-size: 16px;
                color: #878787;
                cursor: pointer;
                padding: 15px 20px 10px 20px;
                position: relative;
                z-index: 3;
                background: $white;
                border: 1px dashed $black_lightc;
                border-radius: 50px;
                
                @include breakpoint(sm) {
                    font-size: 14px;
                    padding: 9px 15px 4px 15px;
                }
                @include breakpoint(xs) {
                    font-size: 12px;
                    padding: 5px 10px 2px 10px;
                }
                i {
                    position: relative;
                    top: 0px;
                    margin-right: 2px;
                }
                
                &.active:before {
                    width: 100%;
                    color: $yellow;
                }
                &:hover,
                &.active {
                    color: $white;
                    background: $Sky_blue;
                    border: 1px solid $Sky_blue;
                }
                &.complete {
                    color: $Sky_blue;
                    @include breakpoint(sm) {
                        color: #6074D4;
                    }
                }
                &.complete:hover:before {
                    width: 0px;
                }
            }
        }
    }
    /*Setp box End*/
    .bottom__row {
        padding: 18px 0;
        border-top: 1px solid #ccc;
        overflow: hidden;
        @include breakpoint(sm) {
            padding: 12px 0;
            background: $white;
            position: static;
            left: 0px;
            bottom: 0px;
            width: 100%;
            z-index: 1;
        }
        .continue__button {
            cursor: pointer;
            background: $Sky_dark_blue;
            color: $white;
            padding: 12px 38px 8px 38px;
            font-weight: 600;
            border-radius: 50px;
            display: block;
            float: right;
            border: none;
            @include breakpoint(sm) {
                float: none;
                margin: 0px auto;
                width: 90%;
                display: block;
                font-size: 18px;
                text-align: center;
            }
            &.confirm {
                padding: 12px 50px;
                font-size: 17px;
                background: #088d0c;
                text-align: center;
                border: none;
                margin: 0px auto;
                display: block;
                @include breakpoint(sm) {
                    padding: 9px 20px;
                }
                i {
                    margin-right: 3px;
                }
                &:hover {
                    background: #007001;
                }
                span {
                    display: block;
                    font-weight: 400;
                    text-transform: uppercase;
                    font-size: 13px;
                }
            }
            &:hover {
                background: $yellow;
                text-decoration: none;
            }

            &.disabled {background: $white_gray1; color: $black;}
        }
    }
    .payment__wrapper .loder__row {
        @include breakpoint(sm) {
            padding: 12px 0;
            background: $white;
            left: 0px;
            bottom: 0px;
            width: 100%;
            z-index: 1;
        }
    }
    /*Radio*/
    .adultType {
        list-style: none;
        padding: 0px;
        margin-bottom: 10px;
        @include breakpoint(sm) {
            margin-bottom: 0px;
        }
        li {
            display: inline-block;
            padding-right: 30px;
            font-size: 14px;
            label {
                cursor: pointer;
                font-weight: 600;
            }
        }
    }
    /*travelerBox box*/
    .travelerBox {
        padding-top: 20px;
        @include breakpoint(sm) {
            padding-top: 0px;
        }
        h4 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 11px;
            color: #4B4B4B;
        }
        p {
            color: #5D5D5D;
        }
        .travel_deal_checkbox {
            margin-top: 27px;
            width: 100%;
            .css-j204z7-MuiFormControlLabel-root {display: -webkit-box;}
            .css-ahj2mt-MuiTypography-root {font-size: 12px;}
            label {
                line-height: 19px;
            }
        }
        .traveler_block {
            border-top: 1px solid #ccc;
            margin-bottom: 30px;
            padding-top: 30px;
            @include breakpoint(sm) {
                padding-top: 20px;
                padding-bottom: 20px;
                margin-bottom: 0px;
            }
            &:first-child {
                border: none;
                margin-top: 0px;
                padding-top: 0px;
            }
        }
    }
    /*Payment box*/
    .paymentBox {
        padding-top: 20px;
        @include breakpoint(sm) {
            padding-top: 0px;
        }
        h4 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 11px;
            color: #4B4B4B;
        }
        .hr-line {
            border-top: 1px solid #ccc;
            margin-top: 30px;
            padding-top: 30px;
        }
        .card__zoom {
            margin-top: 34px;
            font-size: 16px;
            position: relative;
        }

        .radioBtn {

            .form-check-input:checked {
              background-color: $yellow;
              border-color: $yellow;}
              .form-check-input:focus {box-shadow: none;}
          }
          
        
    }
    select::-ms-expand {
        display: none;
    }
    /*Form start */
    .form__block {
        .select-class {
            display: block;
            overflow: hidden;
            position: relative;
            width: 100%;
            cursor: pointer;
            &:before {
                content: "\f107";
                font-family: fontawesome;
                font-size: 23px;
                color: #333;
                font-weight: 700;
                position: absolute;
                right: 1px;
                top: 1px;
                pointer-events: none;
                padding: 7px 10px 0 0;
                background: $white;
            }
        }
        .form-control {
            border-radius: 0px;
            background: $white;
            @include breakpoint(sm) {
                padding: 9px 12px;
            }
            &:focus {
                border-color: $yellow;
                box-shadow: 0 3px 6px #e0e4f9;
            }
        }
        select.form-control:not([size]):not([multiple]) {
            height: auto;
        }
        .is-error {
            position: relative;
            color: red;
            .form-control {
                border-color: red;
            }
            .icon {
                position: absolute;
                top: 13px;
                right: 10px;
            }
        }
        .is-success {
            position: relative;
            .form-control {
                border-color: $yellow;
            }
            .icon {
                position: absolute;
                top: 13px;
                right: 10px;
                color: $yellow;
            }
        }
        .dob_calendar {
            position: absolute;
            right: 11px;
            top: 6px;
            font-size: 25px;
            color: $yellow;
            pointer-events: none;
            cursor: pointer;
        }
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

       &:focus{border-color: inherit; box-shadow: inherit;}
    }
    /*Form end */
    /*Bottom*/
    .bottom_text {
        font-size: 13px;
        padding: 20px 0;
        color: #6B6B6B;
        line-height: 21px;
        .css-j204z7-MuiFormControlLabel-root { margin-right: 0px;}
        p {
            a {
                color: $Sky_dark_blue;
                text-decoration: none;
            }
        }
        label {
            float: left;
        }
    }
    /*Card payment*/
    // input.cc-number.visa {
    //     //background: url(#{$imagePath}payment/visa.png) 99% center no-repeat;
    // }
    // input.cc-number.amex {
    //     //background: url(#{$imagePath}payment/american.png) 99% center no-repeat;
    // }
    // input.cc-number.discover {
    //     //background: url(#{$imagePath}payment/discover.png) 99% center no-repeat;
    // }
    // input.cc-number.mastercard {
    //     //background: url(#{$imagePath}payment/master.png) 99% center no-repeat;
    // }
}


/*payment wrapper end*/

.payment__top {
    padding: 20px;
    background: #135681;
    color: $white;
    @include borderRadius;
    margin: 10px 0;

    .go__back {
        color: $white;
        font-weight: 600;
        i {
            font-size: 16px;
            font-weight: 700;
            margin-right: 3px;
        }
        &:hover {
            text-decoration: none;
            color: $Sky_blue;
        }
    }
    .secure__Box {
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;

        li {
            padding: 10px 20px 10px 50px;
            position: relative;
            line-height: 24px;
            @include borderRadius;
            background: $Sky_blue;

            img {
                position: absolute;
                left: -11px;
                top: -1px;
                font-size: 23px;
                color: $Sky_dark_blue;
            }
			
			i {  position: absolute;  left: 10px;   top: 10px;  font-size: 28px;    color: $white;}

        }
    }
}

.mobile_go_back {
    position: absolute;
    left: 0px;
    top: 0px;
    background: $white;
    width: 45px;
    height: 42px;
    margin: 0px;
    line-height: 38px;
    text-align: center;
    font-size: 20px;
    border-right: 1px solid #ccc;
}

@include breakpoint(sm) {
    .form-group {
        margin-bottom: 0px;
    }
    .formlabel {
        margin-top: 12px;
        margin-bottom: 3px;
    }
}


/*DOB Calendar*/

body.payment-page .ui-datepicker.ui-widget {
    width: 332px;
    margin-left: 15px;
    padding: 0px!important;
    @include breakpoint(md) {}
}

body.payment-page .ui-datepicker .ui-datepicker-header {
    background: #b4c1ff;
}

/*body.payment-page .ui-datepicker td span,
.ui-datepicker td a {
    height: 40px;
    line-height: 37px;
}*/


/*Loader Start*/

.page_loading {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.4);
}

.loading_block {
    background: $white;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    padding: 30px;
    @include borderRadius;
    .loading-icon {
        //background: url(#{$imagePath}fav.png) no-repeat center center;
        width: 94px;
        height: 94px;
        line-height: 90px;
        border-radius: 50%;
        margin: 0 auto;
        position: relative;
    }
    .loading-circle {
        border: 3px solid #dddddd;
        border-radius: 50%;
        border-top: 5px solid $Sky_dark_blue;
        width: 90px;
        height: 90px;
        -webkit-animation: spin 1s linear infinite;
        animation: spin 1s linear infinite;
        border-width: 5px;
    }
    p {
        margin: 0px;
        padding: 0px;
        line-height: 25px;
    }
    &.midloader {
        position: static;
        transform: none;
        padding: 0px;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dot span {
    font-size: 50px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.dot span:nth-child(2) {
    animation-delay: .2s;
}

.dot span:nth-child(3) {
    animation-delay: .4s;
}

@keyframes blink {
    0% {
        opacity: .2;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .2;
    }
}


/*Loader End*/

.review_policy {
    border: 1px solid #4dc0ff;
    padding: 13px 12px 12px 37px;
    font-size: 14px;
    line-height: 25px;
    i {
        position: absolute;
        left: 10px;
        top: 16px;
        font-size: 18px;
    }
}