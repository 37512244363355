.searchBox {
  position: relative;
  z-index: 1;

  .pl-none {
    padding-left: 0px;
    @include breakpoint(md){ padding-left: 15px;}
  }
  .pr-none {
    padding-right: 0px;
    @include breakpoint(md){ padding-right: 15px;}
  }

  &.searchBox2 {
    width: 60%;
    .tabBox {
      justify-content: flex-start;
    }
  }

  @include breakpoint(sm) {
    margin-top: 5px;
  }
  .tabBox {
    padding-left: 0px;
    list-style: none;
    display: flex;
    margin-bottom: 0px;
    justify-content: center;

    @include breakpoint(sm) {
      overflow: hidden;
      margin: 0px -16px;
    }
    li {
      float: left;
      padding: 10px 17px;
      //background: rgba(255, 255, 255, 0.7);
      background: $Sky_dark_blue;
      font-size: 16px;
      color: $white;
      border-right: 1px solid rgba(255, 255, 255, 0.7);
      font-weight: 600;

      cursor: pointer;
      @include breakpoint(sm) {
        font-size: 14px;
        padding: 7px 15px;
        background: $Sky_dark_blue;
      }
      img {
        margin-right: 5px;
        @include breakpoint(sm) {
          height: 22px;
        }
      }

      &.active {
        background: $yellow;
      }

      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-right: 0px solid rgba(255, 255, 255, 0.7);
      }
    }
  }
  /*New Style Star */
  /*search_block*/
  .searchBlock {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    padding: 15px 20px 20px 20px;
    position: relative;
  }
  .flightSearch {
    #feildrowBlock {
      @include breakpoint(sm){
        margin-bottom: 20px;
      }
    }
    .feildrow {
        width: calc(100% - 130px);
        background: rgba(19, 86, 129, 0.13);  
        padding: 10px 0 10px 10px;
        margin-bottom: 5px;
        border-radius: 5px;
        @include breakpoint(sm) {
          width: 100%;
        }
    }

    @include breakpoint(md) {
      display: block;
      padding: 10px;
    }
    @include breakpoint(sm) {
      .box-shadow {
        background: $white;
        box-shadow: 0 3px 6px #bec4df;
      }
    }
    .eng_column1 {
      float: left;
      display: flex;
      width: 50%;
      position: relative;
      @include breakpoint(md) {
        width: 100%;
        margin-bottom: 10px;
      }
      @include breakpoint(sm) {
        width: 100%;
        margin-bottom: 10px;
      }

      .destination {
        margin-right: 10px;
        width: 50%;
        position: relative;
      }
    }
    /*Calumn 2*/
    .eng_column2 {
      float: left;
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @include breakpoint(md) {
        width: 100%;
        margin-bottom: 10px;
      }
      @include breakpoint(sm) {
        margin: 0;
        border: none;
      }
    }
    /*Calumn 3*/
    .eng_column3 {
      float: left;
      width: 50%;
      position: relative;
      @include breakpoint(md) {
        width: 50%;
        margin-bottom: 10px;
      }
      @include breakpoint(sm) {
        width: 100%;
        margin-bottom: 0px;
      }

      .airlinename { 
        width: 100%;
        padding-left: 0px;
        list-style: none;

        li{ 
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 15px;

        .airlineBlock { 
          width: 140px;

          &.booking { width: 91px;}

          div{ 
            width: 40px;
            height: 40px;

            input { width: 100%;}
          }
        }
      }
      }
      
      .TariffBox {
        button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border:1px solid $black_lightc;

          &:focus{ box-shadow: none;}

          &::after { 
            position: relative;
            top: 10px
          }
        }
        
        .dropdown-menu { 
          padding: 15px;
          width: 100%;

          span{ margin-left: 10px;}
        }
      }
    }
    .tooltipBlock {display: inline-flex; align-items: center;
      input { margin-top: 3px;}
      span { margin-left: 10px;}

      button {
        width: 20px; 
        height: 20px;
        background: none;
        border: none;
        margin-bottom: 0px;
        margin-left: 20px;
        padding: 0px;
        line-height: 0px;

        i{ 
          font-size: 20px;
          color: $yellow;
        }
      }
    }
    .dateblock {
      margin-right: 10px;
      width: calc(50% - 10px);
      position: relative;

      .react-datepicker {
        display: flex;
        width: 600px;
        font-size: 14px;
        font-family: "Anek Telugu", sans-serif;
        position: relative;
        z-index: 9999;
        

        @include breakpoint(sm){
          display: block;
          width: 100%;
        }

        .react-datepicker__month-container { width: 50%;background-color: $white;
          
          @include breakpoint(sm){
            width: 100%;
          }
        }

        .react-datepicker__header {
          background-color: $white;
          border-bottom: 1px solid $white;
          border-radius: 0px;
          font-weight: 600;
        }
        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header { margin-bottom: 10px; color: $black; font-size: 20px;}
          
        .react-datepicker__navigation { top: 12px!important;}
        .react-datepicker__day--selected { border-radius: 100%; color: $black; background: $white_gray1;}
        .react-datepicker__navigation { top: 12px;}
        .react-datepicker-popper[data-placement^="bottom"] { padding-top: 0px;}
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected { color: $white; background-color: $yellow; border-radius: 50px;}
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name { margin: 2px 5px;}
        .react-datepicker__triangle::before, .react-datepicker__triangle::after {border-bottom-color: $white;}
        .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {height: 11px;width: 11px;border-width: 4px 4px 0 0; border-color: $Sky_dark_blue;}
      }

    }
    .datepicker {
      padding: 7px 10px 5px 40px;
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: 1px solid $black_lightc;
    }

    .timeblock {
      margin-right: 10px;
      width: calc(50% - 10px);
      position: relative;
    }
    .timePicker {
      padding: 7px 10px 5px 40px;
      width: 100%;
      height: 50px;
      border-radius: 5px;
      border: 1px solid $black_lightc;
      position: relative;
    }
    .TimePick_BTN {
      margin-left: 0px;
      margin-bottom: 0px;
      position: absolute;
      left: 13px;
      top: 8px;
    }

    .input {
      padding: 5px 10px 5px 40px;
      width: 100%;
      height: 50px;
      font-size: 16px;
      color: $black_light1;
      border: 1px solid $black_lightc;
      background: $white;
      line-height: 40px;
    }
    .form-control:focus:focus {
      box-shadow: none;
    }
    .form-select:focus {
      box-shadow: none;
    }
    .form-control > label {
      padding: 15px 20px;
    }
    .form-floating > label {
      left: 30px;
    }
    .form-floating > .form-control:focus ~ label,
    .form-floating > .form-control:not(:placeholder-shown) ~ label,
    .form-floating > .form-select ~ label {
      padding: 6px 10px;
      height: inherit;
      line-height: 13px;
      opacity: 1;
      transform: scale(0.9) translateY(-0.6rem) translateX(0.65rem);
      border-radius: 5px;
      background: $white;
    }
    .form-floating {
      border-radius: 5px;
    }
    .form-floating::after {
      width: 0%;
      height: 3px;
      position: absolute;
      left: 0px;
      bottom: 0px;
      content: "";
      background: $yellow;
      transition: all 0.5s;
    }
    .form-floating::after:focus {
      width: 100%;
    }

    .label {
      padding: 5px 7px 5px 0px;
      float: right;
      width: calc(50% - 7px);
      margin-right: 7px;
      background: $white;
      border-radius: 6px;
      position: relative;
      &.roundtrip {
        display: none;
      }
      &.oneway {
        width: calc(100% - 7px);
      }
      @include breakpoint(md) {
        margin-right: 0px;
      }
      @include breakpoint(sm) {
        margin-right: 0px;
        border-right: none;
        margin-bottom: 10px;
      }
      .label_name {
        font-size: 17px;
        color: #303030;
        transition: all 0.2s ease;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        top: 23px;
        left: 40px;
        pointer-events: none;
        font-weight: 300;
        display: none;
        @include breakpoint(lg) {
          font-size: 16px;
        }
      }
      .inputField {
        padding-left: 45px;
        padding-top: 13px;
        padding-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
        color: #787878;
        border: none;
        outline: none;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include breakpoint(lg) {
          font-size: 14px;
          padding-left: 30px;
        }
        @include breakpoint(sm) {
          padding-bottom: 7px;
          padding-left: 37px;
        }
        &.valid ~ .label_name,
        &.ng-not-empty ~ .label_name {
          top: 5px;
          font-size: 11px;
          color: #858585;
        }
      }
      &.travellerblock {
        width: calc(100% - 10px);
        margin-right: 10px;
        border-right: 0px;
        @include breakpoint(md) {
          width: 100%;
          margin-right: 0px;
        }
        @include breakpoint(sm) {
          width: 100%;
        }
      }
      &.first {
        .text-error {
          left: 0px;
        }
      }

      .bottom_line {
        content: "";
        width: 0;
        background: $Sky_blue;
        height: 2px;
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        transition: 0.5s;
        display: none;
        @include breakpoint(sm) {
          height: 1px;
          bottom: 0px;
        }
      }
      input[type="text"]:focus ~ .bottom_line {
        width: 96%;
        @include breakpoint(sm) {
          width: 100%;
        }
      }
      input[type="text"]:focus ~ .label_name {
        top: 5px;
        font-size: 11px;
        color: #858585;
      }
      input[type="text"]:focus ~ .icons,
      .valid ~ .icons,
      .ng-valid ~ .icons {
        color: $black_light1;
      }
      /*input[type="text"]:focus~.destination-icon{background:url(#{$imagePath}icons/location-selected.svg) left top no-repeat;}
			input[type="text"]:focus~.calender-icon{background:url(#{$imagePath}icons/calender-selected.svg) left top no-repeat;}*/
    }


    // .addfieldRowBlank {
    //   width: 100px;
    // }

    .addfieldBtn {
      width: 110px;
      position: absolute;
      right: 20px;
      bottom: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint(sm){
        width: 55px;
      }

      .add_delete_Row {
        width: 45px;
        height: 45px;
        border: 1px solid $Sky_darken_blue;
        background: $white;
        border-radius: 100px;

        button {
          width: 100%;
          height: 100%;
          text-align: center;
          font-size: 35px;
          color: $Sky_darken_blue;
          line-height: 60px;
          border: none;
          background: none;
        }

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .icons {
    position: absolute;
    left: 13px;
    top: 17px;
    line-height: 20px;
    display: block;
    pointer-events: none;
    font-size: 20px;
    color: $black_light1;
    @include breakpoint(lg) {
      left: 10px;
    }
    // &.person-icon {
    //     top: 17px;
    // }
    // &.location {
    //     top: 17px;
    // }
  }
  .cursor {
    cursor: pointer;
  }

  .moreoptionBtn {
    width: auto;
    display: inline-block;
    background: $Sky_darken_blue;
    border: none;
    font-size: 15px;
    color: $white;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 5px 12px 3px 12px;
    margin-top: 2px;
    margin: 0 10px;
    outline: none;

    &:hover {
      background: $white;
      color: $black;
    }
  }

  .resetBtn {
    width: auto;
    display: inline-block;
    margin: 0 10px;
    background: $Sky_darken_blue;
    border: none;
    font-size: 15px;
    color: $white;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    padding: 5px 12px 3px 12px;
    margin-top: 2px;
    outline: none;

    &:hover {
      background: $white;
      color: $black;
    }
  }

  .searchButton {
    width: 150px;
    display: inline-block;
    background: $yellow;
    border: none;
    font-size: 20px;
    font-weight: 600;
    color: $white;
    text-align: center;
    cursor: pointer;
    border-radius: 6px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 12px;
    margin-top: 2px;
    outline: none;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, 54px);

    &:hover {
      background: $yellow;
    }
  }

  
  
}

.travllerDropdownBox {
  border-top: 1px solid #f4f4f4;
  width: 100%;
  background: $white;
  padding: 15px;
  box-shadow: 0 4px 11px #97979729;
  position: absolute;
  left: 0px;
  top: 46px;
  z-index: 9;
  @include breakpoint(sm) {
    top: 51px;
    width: 100%;
  }
  .head {
    padding: 10px 0 20px 0;
    font-size: 20px;
    width: 100%;
    font-weight: 600;
  }
  .guestBox {
    // padding-right: 30px;
    @include breakpoint(sm) {
      width: 100%;
      border-right: none;
      border-bottom: 1px dashed $black_light9;
      padding-bottom: 15px;
      margin-bottom: 10px;
    }
    .add-traveler {
      font-size: 16px;
      color: $black;
      padding: 0px 0 10px 0;
      clear: both;
      font-weight: 500;
      button {
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 23px;
        font-size: 14px;
        border: 2px solid #989aa2;
        color: #989aa2;
        background: $white;
        border-radius: 100%;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
        font-weight: 600;
        i {
          line-height: 23px;
        }
        &:hover {
          color: $Sky_blue;
          border-color: $Sky_blue;
        }
        &.disable {
          color: #c0cad5;
          border-color: #c0cad5;
          cursor: default;
        }
      }
      .travelers__options {
        float: right;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .number {
        width: 50px;
        display: inline-block;
        text-align: center;
        input {
          width: 100%;
          text-align: center;
          font-weight: 700;
        }
      }
    }
  }

  .unaccompined_block {
    width: 330px;
  }
  .unaccompined {
    padding-top: 10px;
    margin-top: 10px;

    position: relative;

    a {
      color: #333;
      text-decoration: none;
      i {
        position: relative;
        margin-right: 5px;
        top: -2px;
      }
    }
    &:hover a {
      color: $Sky_blue;
    }
    &:hover .tooltip-new {
      opacity: 1;
    }
    .tooltip-new {
      width: 330px;
      left: 138px;
      padding: 10px 15px;
      @include breakpoint(sm) {
        left: 159px;
      }
    }
    .tooltip-new:after {
      left: 11%;
    }
    .closeunaccompanied {
      position: absolute;
      right: 6px;
      font-size: 18px;
      top: 5px;
    }
  }
  .classNameType {
    list-style: none;
    padding-left: 0px;

    li{
      span{ margin-left: 10px;}
    }
  }
  .done_Btn {
    float: right;
    background: $Sky_blue;
    color: $white;
    padding: 10px 30px;
    text-align: center;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: block;
    width: 120px;
    cursor: pointer;
  }


  .economy { list-style: none; padding-left: 0px;
    label { display: flex;}
    input{ margin-right: 10px; width: 15px; height: 20px; border-radius: 5px;}
  }
  

}


