@mixin p_smallFont {font-size: 14px; line-height: 21px;}
@mixin p_mediumFont {font-size: 18px; line-height: 27px;}
@mixin p_bigFont {font-size: 20px; line-height: 30px;}

@mixin Link_smallFont { font-size: 14px; line-height: 21px; text-decoration: none;}
@mixin Link_mediumFont { font-size: 18px; line-height: 27px; text-decoration: none;}
@mixin Link_bigFont { font-size: 20px; line-height: 30px; text-decoration: none;}

@mixin boxshadow {
    border-radius: 15px;
    border: 1px solid #d6d6d6;
    background: $white;
}

@mixin borderRadius {
    border-radius: 10px;
}