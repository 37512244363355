#formCntr {
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #dbedf8;
  z-index: 1;

  
  .uploadbtn {padding: 4px 19px;
    max-width: 150px;
    display: block;
    text-align: center;
    text-decoration: none;
    height: 38px;
    line-height: 37px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 15px;
    color: #fff;
    background: #008ce4;
    cursor: pointer;
    border: none;}


  .formwrapper {
    width: 100%;
    padding: 50px 0;
  }
  .formholder {
    margin-bottom: 20px;
    width: 100%;
    padding: 20px 20px 10px 20px;
    border-radius: 5px;
    background: $white;
    overflow: hidden;
    border: 1px solid $black_lightc;
  }
  h2 {
    font-size: 40px;
    padding-right: 50px;
    display: inline-block;
    position: relative;
  }
  p {
    text-align: left;
    font-size: 16px;
    color: $black_light9;
  }

  .formleft {
    width: 100%;
    position: relative;
    z-index: 3;
    h2 {
      font-size: 40px;
      padding-right: 50px;
      display: inline-block;
      position: relative;

      @include breakpoint (sm){
        font-size: 30px;
        line-height: 35px;
      }
    }
  }

  .img1 {
    width: 80%;
    img {
      width: 100%;
    }
  }
  .formBox {
    width: 100%;
    &.formspace {
      padding: 15px;
    }
    .error { color: $red;}

    .form_col {
      margin-bottom: 10px;
    }
    fieldset {
      border: none;
    }
    label {
      padding: 12px 10px;
      font-size: 16px;
      color: $black_lightc;
      opacity: 0.6;

      &.upload_label { padding: 0px; color: $black_light1; opacity: 1;}
    }
    
    .input {
      padding: 5px 10px;
      width: 100%;
      height: 40px;
      font-size: 16px;
      color: $black_light1;
      background: none;
      border: 1px solid $black_lightc;
      border-radius: 3px;
      line-height: 40px;
    }
    select {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
      width: 100%;
      height: 40px !important;
      font-size: 16px;
      color: $black_light1;
      background-color: transparent;
      border-radius: 3px;
      border: 1px solid $black_lightc;
      line-height: 40px !important;
    }
    .textarea {
      width: 100%;
      height: 80px;
      background: transparent;
      border-radius: 0px;
      border: 1px solid $Sky_blue;
    }
    .submitBtn {
      padding: 4px 30px;
      max-width: 150pt;
      display: block;
      text-align: center;
      text-decoration: none;
      width: 100%;
      height: 45px;
      line-height: 45px;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 18px;
      border-radius: 5px;
      color: $white;
      background: $Sky_blue;
      cursor: pointer;
      border: none;
      i {
        margin-right: 10px;
      }
      &:hover {
        background: $yellow;
        transition: all 0.5s;
      }
    }
    .forgotlink {
      font-size: 16px;
      color: $Sky_blue;
      text-decoration: none;
    }

    .form-control:focus {
      box-shadow: none;
    }
    .form-floating > .form-control:focus ~ label,
    .form-floating > .form-control:not(:placeholder-shown) ~ label,
    .form-floating > .form-select ~ label {
      padding: 6px 10px 0 10px;
      height: inherit;
      line-height: 13px;
      opacity: 1;
      transform: scale(0.9) translateY(-0.6rem) translateX(0.65rem);
      background: $white;
    }
    .form-select:focus {
      box-shadow: none;
    }
  }
  
}


#formCntr .formBox .input:focus + label{ color:#333; opacity: 1; }