/* Profile menu login*/
.content_list { padding: 0px;}
.sucessfull_message { position: absolute;right: 18px; top: 30px; background: #d4edda; padding: 15px; border: 2px solid #3c763d; border-radius: 2px;  z-index: 9;}

/* User Profile Dasboard Css start*/
#profileCntr { padding: 20px 0; width: 100%;  background: #dbedf8;
.leftCntr { width: 270px; float: left; background: #135681; border-radius: 10px; overflow: hidden; box-shadow: 0 0 6px #00000029;
	@include breakpoint(lg){width: 100%;   margin-bottom: 10px;  margin-top: -10px;}
	}

}
.profile_imageBlock {
  padding: 20px 18px;
  overflow: hidden;
  text-align: center;
  background: linear-gradient( 90deg, rgb(73 151 255) 0%, rgb(59 170 248) 100% );
  @include breakpoint(lg){width: auto;  float: none;  padding: 10px 21px; margin: -10px -20px 0; }
  .name_col { padding-top: 10px; }
  .image { width: 71px; height: 71px;  margin: 0 auto;  background: #fff;  border-radius: 100%; 
	 @include breakpoint(lg){ width: 50px; height: 50px;  float: left;}
  }
}

.profile_imageBlock .image span.changeColor {
  font-size: 32px;
  color: #3a3939;
  font-weight: 700;
  display: block;
  line-height: 85px;
  text-align: center; letter-spacing: 3px;
   @include breakpoint(lg){font-size: 21px;   line-height: 50px; }
}

.profile_imageBlock .name {
  padding-top: 10px;
  font-size: 16px;
  color: #fff;
  @include breakpoint(lg){text-align: left;
    padding: 0px; }
}



.profile_imageBlock .small {
  padding-top: 5px;
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
}

/*Left Menu*/
.leftMenu {
  width: 100%;
  overflow: hidden;
  @include breakpoint(lg){display: none;}
  ul { margin: 0; padding: 0 0 30px; list-style: none;
		li { display: block;  height: 55px;  position: relative;  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
			a { padding: 17px 15px 17px 15px; display: flex; align-items:center;  font-size: 15px;  color: #fff;  position: relative;  z-index: 1;  text-decoration: none; 
			&.active {color: #4997ff; background: #fff;  font-weight: 500; }
			i { font-size: 23px;  line-height: 23px;  margin-right: 10px; }
			}
		}
	}
}



#profileCntr .rightCntr {
  padding-left: 25px;
  width: calc(100% - 270px);
  float: left;
  @include breakpoint(lg){ width: 100%;
    padding: 0px;}
}



.tabBox {  width: 100%; overflow: hidden; position: relative;
  ul { margin: 0;  padding: 0 0 12px;  list-style: none;  position: relative;  z-index: 1;
	li {  padding: 0 10px 0 0;  float: left;  
	a { cursor:pointer; padding: 10px 20px 8px 20px; display: block;  font-size: 13px;  color: #333;  font-weight: 600;  border: 1px solid #4997ff;  text-decoration: none;  background: #fff;  border-radius: 5px;
		@include breakpoint(sm){padding: 8px 15px;}
		&.active {  color: #fff;  background: linear-gradient( 90deg, rgb(73 151 255) 0%, rgb(59 170 248) 100% );}
		&:hover { background: #4997ff;  color: #fff; }
		}
		}
		}
		}



.tabContent,
.tabContent2 {
  padding: 25px 0 0;
  width: 100%;
}

.formBox {
  padding: 15px;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 6px #00000029;
  border-radius: 10px;

[class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}
.title h2 {
  margin: 0;
  font-size: 20px;
  color: #4f4f50; line-height:inherit;
  display: inline-block;
}
.title .edit {
  padding: 7px 18px;
  font-size: 14px;
  color: #4f8ffc;
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #4f8ffc;
  min-width: 90px;
  font-weight: 600;
  text-decoration: none;
  float: right;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}
.form-group {
  padding-bottom: 10px;
}
.form-group .lable {
  padding-bottom: 6px;
}
.star {
  color: red;
}
 .textbox {
  padding: 8px 12px 7px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  width: 100%;
  border: 1px solid #b7b7b7;
  border-radius: 0;
  box-shadow: none;
  resize: none;
}
.button {
  padding: 13px 30px 10px;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  border: none;
  background: rgb(247, 125, 19);
  background: linear-gradient( 90deg, rgb(73 151 255) 0%, rgb(59 170 248) 100% );
  border-radius: 6px;
  position: relative;
}
.label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  display: block;
  padding-bottom: 6px;
}

}



.error_text {
  color: #f54336;
  font-size: 13px;
  padding-top: 5px;
}

.button_loding_div {
  background: inherit;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 48px;
  padding: 13px 0 15px 30px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  display: block;
  border-radius: 3px;
}

.button_loding_div .button_loader.blnk {
  left: 37%;
  transform: translateX(-50%);
}

.formBox .button.grayBtn {
  background: #ececec;
  margin-right: 8px;
  color: #535353;
}

.tabContent p {
  margin-bottom: 5px;
}

.content_detail {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content_detail li {
  line-height: 25px;
  font-size: 14px;
}

.content_detail li .label {
  width: 215px;
  display: inline-block;
  padding: 0;
}



.tabContent2 h2 {
  font-size: 21px;
}

.tabContent2 .table-bordered th {
  white-space: nowrap;
}

.tabContent2 .table-bordered td,
.tabContent2 .table-bordered th {
  padding: 9px;
  vertical-align: middle;
}

.tabContent2 th {
  background: #135681;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
}

.tabContent2 .table-bordered td {
  font-size: 13px;
  background: #fff;
}

.tabContent2 .table-bordered td,
.tabContent2 .table-bordered th {
  padding: 9px;
  vertical-align: middle;
}

.bi-airplane {
  color: #1b75bc;
}

.formBox .title .edit:before {
  font-family: bootstrap-icons;
  content: "\f4cb";
}

.select_dropdown {
  position: relative;
}

.select_dropdown:before {
  content: "\f282";
  font-family: bootstrap-icons;
  font-size: 16px;
  position: absolute;
  right: 5px;
  top: 7px;
  height: 20px;
  width: 20px;
  color: #999;
  pointer-events: none;
}

.select_dropdown select {
  padding: 9px 12px 7px;
  font-size: 14px;
  color: #797979;
  width: 100%;
  border: 1px solid #b7b7b7;
  background-color: #fff;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  height: 38px;
}

#profileCntr {
	label {font-weight: 600; padding-bottom:5px; display:inline-block; }
}

#profileCntr .rightCntr h2.main_title {
  font-size: 24px;
}

.travelCouponBox {
  background: #f6f6f6;
  margin-top: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c0c3ca;
  width: 100%;
  float: left;
  padding-right: 10px;
}

.travelCouponBox .travel-img {
  position: relative;
  margin-top: -1px;
  height: 248px;
  overflow-x: hidden;
  margin-bottom: 0;
}

.travelCouponBox .coupon-top {
  padding-top: 25px;
}

.travelCouponBox h3 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 14px;
}

.travelCouponBox p {
  font-size: 15px;
  color: #5b616f;
}

.travelCouponBox .text-color {
  color: #4997ff;
}

.travelCouponBox .review_tnc {
  padding: 20px 0;
  display: block;
}

.custom-tooltip {
  position: relative;
  display: inline-block;
}

.travelCouponBox .learn_more {
  background: rgb(247, 125, 19);
  background: linear-gradient( 90deg, rgb(73 151 255) 0%, rgb(59 170 248) 100% );
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding: 10px 20px;
  display: inline-block;
  border-radius: 5px;
}

.travelCouponBox .learn_more:hover {
  color: #fff;
}

.travelCouponBox .custom-tooltip .tooltip_detail {
  width: 430px;
  left: -183px;
  top: 43px;
}

.custom-tooltip .tooltip_detail {
  display: none;
  background: #fff;
  font-size: 12px;
  padding: 6px;
  position: absolute;
  left: 0;
  top: 26px;
  width: 230px;
  border: 1px solid #94afc6;
  z-index: 3;
  white-space: normal;
}

.custom-tooltip:hover .tooltip_detail {
  display: block;
}

.center-sec {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loadingimg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-sec .login_popup {
  position: inherit;
  background-color: rgba(0, 0, 0, 0);
  display: block !important;
}

.login-sec .login_popup .loginBox .close {
  display: none;
}

.login-sec {
  background: rgba(13, 110, 253, 0.25);
}

/*New Css*/
.profile_main_page { background: #fff; border: 1px solid #ccc;border-radius: 5px;margin-bottom: 20px;  overflow: hidden;  padding: 20px 20px;  width: 100%;
	h2 { color: #424242; font-size: 30px;  font-weight: 500;  line-height: 35px; margin-bottom:30px; }	
	ul{ list-style:none; display: flex; justify-content: space-around; 
		li { cursor:pointer; width:180px; height: 180px;  border: 2px solid #135681; display: flex; align-items: center; border-radius: 100%; justify-content: center;   font-size:18px;
		flex-direction: column; text-align: center;  font-weight: 500;  line-height: 21px; 
			i { font-size: 50px; margin-bottom:20px; display:block; }
			a { color:#333; text-decoration: none;  }
			&:hover { border-color: #f6871f; 
				a {color:#f6871f; }
			}
		}
	}
}

/**/
.bankInformation {
	h2 {color: #424242; font-size: 25px;  font-weight: 500;  line-height: 30px; margin:15px 0; }
	p { font-size: 15px; line-height: 25px; }
}