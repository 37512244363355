#Reg__SuccesfulPopup {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
    background: rgba(0,0,0,0.9);


    .Reg__SuccesfulBlock {
        
        width: 600px;
        background: $white;
        @include borderRadius;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: row;
        position: relative;
        background-size: cover;
    
        
        h3{ margin-bottom: 0px; margin-top: 20px; font-size: 25px;color: $black; font-weight: 700;}
        .girlimg { width: 50%;
            img{ width: 100%; left: 0px; position: relative;}
        }
        .checkIcon {width: 50px; height: 50px;}
        
        .content {
            max-width: 50%;
            position: absolute;
            right: 0px;
            padding: 30px 15px;
            @include borderRadius;
            background: $white;
            text-align: center;
            overflow: hidden;
            font-size: 12px;
            color: $black;
        }
        .Singin_button { 
            display: block;
            border: none;
            border-radius: 50px;
            background: $yellow;
            font-size: 16px;
            font-weight: 500;
            color: $white;
            margin: 20px auto 0 auto;
            padding: 5px 10px 0 10px;
        }
    }
}