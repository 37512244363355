.price__Box {
    @include boxshadow;
    padding: 15px;
    @include breakpoint(md) {
       
        margin-bottom: 40px;
    }
    @include breakpoint(sm) {
        margin-left: -15px;
        margin-right: -15px;
        margin-top: 20px;
    }

    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root { padding: 0px;}
    
    .head {
        position: relative;
        border-bottom: 1px solid #ccc;
        h4 {
            font-size: 16px;
        }
    }
    ul {
        list-style: none;
        padding-left: 0px;
        li {
            padding: 10px 0;
            border-bottom: 1px solid #e1e1e1;
            font-size: 13px;
            color: #4B4B4B;
            font-weight: 600;
            i {
                font-size: 16px;
                margin-left: 4px;
                font-weight: 700;
            }
            .accordian-slide {
                padding-left: 13px;
                padding-top: 5px;
                font-size: 12px;
                color: #6B6B6B;
                font-weight: 400;
            }
            .accordian_toggle {
                cursor: pointer;
            }
            .disscount--price {
                padding: 10px 0;
                color: $yellow;
            }
            .css-1elwnq4-MuiPaper-root-MuiAccordion-root { box-shadow: inherit!important;}
            .css-o4b71y-MuiAccordionSummary-content { display: block;}
        }
    }
    .total_price {
        font-weight: 700;
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
        color: $Sky_dark_blue;
    }
    .price__note {
        font-size: 11px;
        padding: 12px 0;
        color: #666;
    }
}


/*Secure*/

.securebooking__Box {
    @include boxshadow;
    margin-top: 20px;
    padding: 15px;
    @include breakpoint(sm) {
        margin: 0 -15px 70px -15px;
    }
    h4 {
        padding-bottom: 10px;
        font-size: 16px;
        border-bottom: 1px solid #ccc;
    }
}


/*Coupon here*/

.cuponBlock {
    width: 100%;
    overflow: hidden;
}

.cuponBlock .inputText {
    width: calc(100% - 73px);
    border: 1px solid #ccc;
    padding: 5px 10px;
    float: left;
    font-size: 15px;
    color: $yellow;
    font-weight: 700;
}

.cuponBlock .submit {
    border: none;
    background: #00ab53;
    padding: 7px;
    color: $white;
    width: 73px;
    float: left;
    cursor: pointer;
}

.cuponBlock .submit:focus {
    border: none;
    outline: none;
}

.cuponBlock .submit.remove {
    background: $Sky_dark_blue;
}

.cuponBlock .sucessfull {
    color: #00ab53;
}

.Refundable {
    padding: 10px 0 5px 0;

    button{
        display: inline-block;
        border: none;
        background: none;
        color: $green;
        i{ margin-right: 5px;}
    }
}

.timerPayment { padding: 10px; margin-bottom: 15px; width: 100%; font-size: 15px; display: flex; align-items: center; justify-content: center; background: $white_gray1;
    .iconBlock { min-width: 120px; padding: 10px 10px 5px 10px; margin-right: 10px; text-align: center; border-radius: 5px; background: $yellow; font-size: 20px; color: $white; font-weight: 600;
        .icon{margin-right: 5px;}
    }
}