#bannerCntr {
  width: 100%;
  position: relative;

  figure {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }
  img {
    width: 100%;
    object-fit: cover;
  }
  .caption_holder {
    width: 45%;
    left: 10%;
    right: 20%;
    text-align: left;
    top: 25%;
    @include breakpoint(lg) {
      top: 15%;
    }
    @include breakpoint(md) {
      top: 5%;
    }
    @include breakpoint(sm) {
      padding: 20px;
      text-align: center;
      bottom: inherit;
      width: 80%;
      background: rgba(0, 0, 0, 0.5);
      span {
        color: $white;
      }
      p {
        color: $white;
      }
      span {
        color: $white;
      }
    }
  }
  h1 {
    margin: 20px 0 0 0;
    line-height: 60px;
    font-size: 76px;
    color: $yellow;
    font-weight: 600;
    text-transform: uppercase;
    @include breakpoint(lg) {
      font-size: 50px;
    }
    @include breakpoint(md) {
      margin-top: 5px;
      font-size: 40px;
    }
    @include breakpoint(sm) {
      font-size: 35px;
      line-height: 40px;
    }
  }
  p {
    font-size: 35px;
    color: $black_light1;
    @include breakpoint(lg) {
      font-size: 25px;
    }
    @include breakpoint(md) {
      font-size: 18px;
    }
    @include breakpoint(sm) {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
  span {
    font-size: 25px;
    color: $black_light1;
    @include breakpoint(md) {
      font-size: 16px;
    }
  }
  .more_btn {
    a {
      display: inline-block;
      @include breakpoint(sm) {
        font-size: 14px;
        padding: 10px 20px 5px 20px;
      }
    }
  }

  .swiper-pagination { z-index: 99;
    .swiper-pagination-bullet {width: 20px; height: 5px; border-radius: 25px; opacity: 1; background-color: $Sky_blue;}
    .swiper-pagination-bullet-active { background-color: $yellow;}
  }

}

/*Banner Box Home page*/

#banneruserCntr {
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background:url(#{$imagePath}mob_banner1.jpg) center top no-repeat;
  z-index: 2;

  background-size: cover;
  @include breakpoint(md) {
    height: 500px;
    padding-bottom: 30px;
  }
  @include breakpoint(sm) {
    height: auto;
    padding: 100px 0 100px 0;
  }

  .notification {
    position: absolute;
    right: 50px;
    top: 0px;
    background: $yellow;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 10px 0;

    ul {
      list-style: none;
      display: flex;
      padding-left: 0px;
      margin-bottom: 0px;

      li {
        padding: 0px 15px;
        border-right: 1px solid $white;

        &:last-child {
          border-right: 0px;
        }

        a {
          font-size: 16px;
          color: $white;
          text-decoration: none;
          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            color: $black;
            text-align: center;
            background: $white;
            border-radius: 100%;
          }
        }
      }
    }
  }
}

#InnerBannerCntr {
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(lg){ height: 300px;}

  &::after {width: 100%; height: 100%; content: ''; position: absolute; left: 0px; top: 0px; z-index: 0; background-color: rgba(0, 0, 0, 0.5);}

  .container { z-index: 1; text-align: center;}

  .content { width: 600px; margin: 0 auto;
    @include breakpoint(md){ width: 100%;}
  }

  h1{ font-size: 60px; margin-bottom: 0px; font-weight: 600; color: $white; 
    @include breakpoint(md){ font-size: 40px;}
  }
  p{ font-size: 20px; color: $white;}
  .agent { display: inline-block;}
}

