.bagguage_fees {color: #6b6b6b;position: relative;display: inline-block;}
.bagguage_fees ul {
display: flex;
list-style: none;
}

.bagguage_fees ul li {
margin-right: 15px;
position: relative;
}

.bagguage_fees ul li.check {
justify-content: flex-end;
font-family: "bootstrap-icons";
color: #3ab54a;
}

.bagguage_fees ul li.check:before {
content: "";
position: absolute;
top: -1px;
left: 10px;
//background: url(#{$imagePath}baggage-included.svg) no-repeat;
height: 15px;
width: 15px;
background-size: cover;
}

.bagguage_fees ul li.noCheck:before {
content: "";
position: absolute;
top: -1px;
left: 10px;
//background: url(#{$imagePath}close-icon.svg) no-repeat;
height: 15px;
width: 15px;
background-size: cover;
}

.bagguage_fees:hover .tooltipBox {
display: block;
}

.bagguage_fees .tooltipBox {
width: 330px;
font-weight: 600;
left: -85%;
}

.bagguage_fees .tooltipBox .baggesInfo {
flex-direction: column;
padding-left: 28px;
}

.bagguage_fees .tooltipBox .baggesInfo li {
width: 100%;
margin: 0;
padding: 3px 0;
text-transform: lowercase;
}


.bagguage_fees .tooltipBox:after, .bagguage_fees .tooltipBox:before {
left: 18%;
}

.bagguage_fees .tooltipBox .row {
padding-bottom: 10px;
}

.bagguage_fees .tooltipBox .row:last-child {
padding-bottom: 5px;
}

.bagguage_fees .tooltipBox .leftSec {
color: #303030;
font-size: 13px;
padding-left: 40px;
position: relative;
}

.bagguage_fees .tooltipBox .bag {
width: 20px;
position: absolute;
left: 10px;
top: 0;
}

.bagguage_fees .tooltipBox .bag.last {
height: 22px;
}

.bagguage_fees .tooltipBox p {
margin: 0;
font-weight: normal;
color: #919192;
font-size: 12px;
line-height: 1.3;
}

.bagguage_fees .tooltipBox .included {
color: #0e42ea;
}



/*Card*/
// .card__zoom {
//     position: absolute;
//     font-size: 11px;
//     padding-top: 0px;
//     font-weight: 500;
//     display: inline-flex;
//     left: 0;
//     top: 45px;
// }

//     .card__zoom i {
//         color: #ccc;
//         padding-right: 5px;
//     }

//     .card__zoom:hover .cardInfoTooltip {
//         display: block;
//     }

//     .card__zoom .cardInfoTooltip {
//         position: relative;
//         right: 0;
//         width: 510px;
//         background: #fff;
//         z-index: 1;
//         box-shadow: 0 0 6px #00000029;
//         padding: 13px;
//         top: 46px;
//         font-size: 10px;
//         display: none;
//     }

//         .card__zoom .cardInfoTooltip strong {
//             display: block;
//             padding: 10px 0;
//             min-height: 42px;
//             font-weight: 600;
//         }

//         .card__zoom .cardInfoTooltip p {
//             font-weight: 500;
//         }
		
		
		.securebooking__Box {
    border-radius: 5px;
    box-shadow: 0px 4px 5px #0000000A;
    border: 1px solid #e8e8e8;
    background: #c8e7cc;
    padding: 10px 0;
    font-size: 14px;
    margin-top: 20px;
}


    .securebooking__Box h4 {
        padding-bottom: 10px;
        font-size: 16px;
        border-bottom: 1px solid #ccc
    }
	
	
.timerSec {
    width: 100%;
    display: flex; @include boxshadow;
    align-items: center;
    justify-content: center;
    padding: 10px; margin-top: 20px;
    #timerCount { color: #FF7700; padding-left: 5px;}
    
}

    .timerSec p {
        font-size: 16px;
        font-weight: 600;
        color: #000000; margin: 0px;
    }
