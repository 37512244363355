.insuranceList {margin: 0; padding: 0; list-style:none; overflow:hidden;
	ul{ margin: 0; padding: 0; list-style:none; }
	h5 { font-size: 14px; font-weight: 600; }
	li {  line-height: 18px;  font-size: 13px;  margin:10px 0px;  position: relative;  padding-left: 20px; 
	&:before {  position: absolute;  color: #4fb67a;  font-family: fontawesome;  font-weight: 900;   content: "\f00c";  left: 0; }
	p { margin: 0px; }
	}
	&.li50 { overflow: hidden; margin-right:-20px; 
	@include breakpoint(sm){ margin: 0; }
		li { width: 50%; float: left; padding-right:20px; margin-bottom: 0px;
		@include breakpoint(sm){ width: 100%; }
		&.moreList { padding: 0; margin: 0px;  width: auto;
		&:before { content:''; display:none; }
	}
		}
	}
	.manymore { color: #FC5A4C;  cursor: pointer; 
		&:before { content:''; display:none; }
	}
	
}

.insurance-price { font-size: 22px; font-weight: 600; line-height: 1;  padding: 20px 0 20px 0px;
.per-pax { font-size: 13px; line-height: 1;  display: inline-block; font-weight:400;  margin-left: 5px;}
}



.radioSelect-option {
	ul{ margin: 0px; padding: 0px; 
		li {list-style: none;   margin: 0 30px 7px 0;  padding: 10px 12px;  position: relative;  width: calc(50% - 30px);  float: left;  border: 1px solid #ccc;
				@include breakpoint(sm){ width: 100%; }
		}
	}
}


/*REfundable */
.payment-refundable{
.refund-details{ position:relative;
.icon_image { position: absolute;  right: 30px;  top: 10px; }
}
.upgrade-txt {  border-radius: 5px;  border: 1px solid #005dba;   padding: 12px;   font-size: 12px;  margin: 10px 0; }
.refund-subtital { font-size: 13px;
    color: #008c11;
    background: #f2ffee;
    border-left: 5px solid #43b851;
    padding: 10px 40px 10px 13px;
    margin: 5px 0 18px;
    clear: both;
    border-radius: 3px;
	
}
}
/*REfundable */

.affirm-tooltip { position: relative;
    display: inline-block; 
	.fa-info {  display: inline-block; width: 13px;  height: 13px;  text-align: center;  line-height: 13px;  border: 1px solid #1b75bc;  border-radius: 100%;  font-size: 10px;
    color: #1b75bc; } 

.promo-detail { display: none;  background: #fff;  font-size: 12px;  padding: 3px 8px;  position: absolute;  left: 50%;  top: 24px;  transform: translateX(-50%);
width: 218px;  z-index: 2;  border: 1px solid #1b75bc;   color: #57585a;  font-weight: 400;}

 ul.affirm_list {  padding: 10px 0 0;   margin: 0;
	li {display: flex;  align-items: center;  justify-content: space-between;  padding: 6px 0;  border-bottom: 1px dashed #ccc; 
		.price {  color: #4a4af4;   font-weight: 700;}
		}
	}
	&:hover .promo-detail { display:block; }
	}
	
.affir_content_block { padding-left: 25px;}
.pay-with-cc {overflow: hidden; width: 100%;  padding-top: 16px; }
.affirm_radio {padding: 15px 0; border-top: 1px solid #ccc;  margin-top: 16px; }
.required_star { color:red; }