// airNewsCntr start
#airNewsCntr {
  padding: 50px 0;
  width: 100%;
  background: $white_gray1;
  position: relative;
  z-index: 1;

  .newBox {
    width: 100%;
    margin-bottom: 20px;
    background: $white;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

    h3 {
      padding: 5px 20px;
      background: $yellow;
      a {
        font-size: 18px;
        color: $white;
        text-decoration: none;
      }
    }

    ul {
      padding: 0px 20px;
      list-style: none;
      li {
        padding: 10px 0 5px 0;
        border-bottom: 1px dashed $black_lightc;
        overflow: hidden;
        a {
          font-size: 15px;
          color: $black_light1;
          text-decoration: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
// airNewsCntr ends

#TimePickBackgroundOverlay {
  display: none !important;
}

// airlineLogoCntr start
#airlineLogoCntr {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 50px 0;
  background: $Sky_darken_blue;

  .swiper-slide {
    img{ width: 60%; margin: 10px 20px;}
  }

  .sliderBlock { width: 100%; overflow: hidden; @include borderRadius; background: $white;
    .swiper-button-prev, .swiper-button-next { width: 30px; height: 30px; background: $Sky_darken_blue; border-radius: 20px;}
    .swiper-button-prev::after, .swiper-button-next::after { width: 50px; height: 50px; font-size: 0px;}
    .swiper-button-prev::after { background: url(../../resources/images/icons/arrow-bar-left.svg) center center no-repeat; background-size: 60%;}
    .swiper-button-next::after { background: url(../../resources/images/icons/arrow-bar-right.svg) center center no-repeat; background-size: 60%;}
  }

  .sliderbottom {
    width: 100%;
    padding: 10px 20px;
    background: $white;
    display: flex;
    justify-content: space-between;
    border-top: 5px solid $Sky_darken_blue;

    .editlist {
      font-size: 15px;
      color: $yellow;
      text-decoration: none;
      font-weight: 600;
    }
  }
}
// airlineLogoCntr ends

// serviceBox start
.serviceBox {
  padding: 100px 0;
  width: 100%;
  background: $white;
  @include breakpoint(md) {
    padding: 50px 0;
  }

  @include breakpoint(sm) {
    .mb_60 {
      margin-bottom: 120px;
    }
  }

  .m_title {
    margin-bottom: 100px;
  }
  .service {
    padding: 20px;
    width: 100%;
    height: 100%;
    text-align: center;
    background: $white;
    box-shadow: 0px 0px 5px rgb(35 35 35 / 30%);

    figure {
      width: 150px;
      height: 150px;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin: -100px auto 0 auto;
      overflow: hidden;
      background: $white;
      border: 2px dashed $yellow;
      img {
        width: 50%;
        z-index: 2;
      }
      &::after {
        width: 90%;
        height: 90%;
        content: "";
        border-radius: 100%;
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: $white;
        border: 2px solid $yellow;
      }
    }
    p {
      font-size: 16px;
      color: $black_light1;
      line-height: 24px;
      overflow: hidden;
      transition: all 0s ease;
    }
    h4 {
      margin: 20px 0;
      overflow: hidden;
    }

    // &:hover>figure{ height: 150px; transition: all .4s ease-in-out;}
    // &:hover p { display: block; opacity: 1; transition: opacity 1.5s ease-in-out;}
  }
}
// serviceBox ends

// aboutCntr start
#aboutCntr { padding: 50px 0; width: 100%; overflow: hidden;
  .img {margin: 0 0 15px 50px; padding: 100px 100px 100px 0; width: 500px; float: right;position: relative;
    @include breakpoint(md){width: 300px;}
    @include breakpoint(sm){ padding: 50px 50px 50px 0; float: none; width: 100%;}

    img { width: 100%; z-index: 2; position: relative;}
    &:after{ width: 50%; height: 100%; content: '';position: absolute; right: 0px; top: 0px; background: $white_gray1; z-index: 1;}
  }
  .content { padding-top: 150px; 
    @include breakpoint(md) { padding-top: 70px}
    @include breakpoint(sm) { padding-top: 0px}
  }
  p { @include p_mediumFont;}
}
// aboutCntr ends

// missionCntr start
#missionCntr { padding: 100px 0 50px 0; width: 100%; text-align: center; overflow: hidden; background: $white_gray1;
  @include breakpoint(sm){ padding: 50px 0px;}

  .holder { margin-bottom: 50px; width: 100%; display: flex; flex-wrap: wrap; 
    @include breakpoint(sm){ margin-bottom: 0px;}
  }
  .block { margin: 0 10px; padding: 30px 50px; width: calc(33.3% - 20px); background: $white;
    @include breakpoint(md){ padding: 30px 15px;}
    @include breakpoint(sm){width: 100%; margin-bottom: 20px;}

    .icon { width:100px; margin: 0 auto 20px auto; height: 100px; border-radius: 100%; display: flex; justify-content: center;align-items: center; background: $white_gray1;
      img {width: 50%;}
    }
    h4{font-size: 20px; color: $Sky_darken_blue;}
  }
  p{ @include p_mediumFont;}
}
// missionCntr ends
