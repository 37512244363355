// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,500&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@100;200;300;400;500;600;700;800&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

$imagePath: "../../resources/images/";
$imagePathPopup: "../../../resources/images/";

// colors 

$white: #fff;
$white_gray1: #f4f9fd;
$black: #0a0a0a;
$black_light1: #424242;
$black_light9: #999;
$black_lightc: #ccc;
$black_lighte: #eeeeee;
$Sky_blue: #008ce4;
$Sky_dark_blue: #135681;
$Sky_darken_blue: #024874;
$yellow: #f6871f;
$red: #fa0404;
$green: #00ab53



