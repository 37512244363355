.FarerulHide {display: none!important;}
.Fareruleoverlay { padding: 100px 0; display: block; width: 100vw; height: 100vh; z-index: 1000; overflow-y: hidden; position: fixed; left: 0px; top: 0px; background: rgba(0, 0, 0, 0.5);
  
  @include breakpoint(sm){
    padding: 50px; 
  }
  .cover { width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }
  
 .Farerule {
    padding: 30px 20px 20px 20px;
    position: relative;
    width: 650px;
    height: auto;
    @include borderRadius;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    background: $white;
    z-index: 3;
    
    @include breakpoint(sm){
      width: 100%;
    }

    p{ margin-bottom: 0px; line-height: 24px;
      a{color: $Sky_dark_blue; text-decoration: none; font-weight: 600;}
    }
    .closeBtn { font-size: 20px; color: $yellow; position: absolute; right: 10px; top: 10px; border: none; background: none;}

    h4 {
      padding: 10px 20px 5px 20px;
      margin-bottom: 0px;
      color: $white;
      background: $Sky_dark_blue;
    }
  
    .ruleContent { max-height: 480px; overflow-y: auto;}
  }
}
.fareRuleShow {overflow: hidden;}
  
  //Fareruleoverlay ends