//price_breakdown_overlay start
.price_breakdown_overlay {
    width: 100%;
    height: 100%;
    background: rgba(218, 209, 209, 0.5);
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 99;
  
    // .price_breakdown_overlay_inner {
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
  
    //   .price_breakdown {
    //     position: relative;
    //     padding: 20px;
    //     width: 450px;
    //     @include borderRadius;
    //     box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    //     background: $white;
  
    //     .closeBtn {
    //       float: right;
    //       margin-bottom: 10px;
  
    //       .css-i4bv87-MuiSvgIcon-root {
    //         color: $yellow;
    //       }
    //     }
  
    //     h4 {
    //       padding: 10px 20px 5px 20px;
    //       margin-bottom: 0px;
    //       color: $white;
    //       background: $Sky_dark_blue;
    //     }
  
    //     thead th {
    //       padding: 0px;
    //     }
    //     tbody tr {
    //       border-style: dashed;
    //       border-color: $black_light1;
  
    //     }
    //   }
    // }
  
  }
  
  .hidePrice { height: 0px; visibility: hidden;}
  .showPrice { height: auto; visibility: visible; transition: all 5s;}
  .price_breakdown {
    position: absolute;
    right: 0px;
    bottom: 50px;
    padding: 20px;
    width: 450px;
    @include borderRadius;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    background: $white;
    z-index: 3;
    
  
    .closeBtn {
      float: right;
      margin-bottom: 10px;
  
      .css-i4bv87-MuiSvgIcon-root {
        color: $yellow;
      }
    }
  
    h4 {
      padding: 10px 20px 5px 20px;
      margin-bottom: 0px;
      color: $white;
      background: $Sky_dark_blue;
    }
  
    thead th {
      padding: 0px;
    }
    tbody, td, tfoot, th, thead, tr {
      border-color: $black_light1;
      border-style: dashed;
    }
    table thead tr th, table tbody tr th, table tbody tr td{ text-align: center;}
    table thead tr th, table tbody tr th, table tbody tr td{ text-align: center;}
    table thead tr{ background: $white_gray1;}
    table thead tr th{ padding: 10px 0 5px 0;}
    table tbody tr th:first-child { text-align: left;}
    table thead tr th:first-child { padding-left: 10px; text-align: left;}
    .total { font-size: 18px; color: $yellow;}
  }
  //price_breakdown_overlay ends